/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, {  useEffect,  useState,  useContext,  useCallback,  useRef,} from "react";

import {
  Card,  ViewContext,  AuthContext, Stat,  ProgressBar,  Image,  Button,  Link,  SocialShare,  Search,  Message,  Grid,  Animate,  Feedback,
  useAPI,  useNavigate,  DashboardNav,  SocialSignin,  Header, SignIn} from "components/lib";

//import { useWeb3React } from "@web3-react/core";
//import { /*useConnection,*/ useWallet } from "@solana/wallet-adapter-react";
//import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
//import { Tooltip } from 'react-tooltip'
//import { Keypair, SystemProgram, Transaction } from '@solana/web3.js';
import Axios from "axios";
import { /*ConsoleView,*/ isMobile } from "react-device-detect";
//import 'react-tooltip/dist/react-tooltip.css'

import Style from "./dashboard.tailwind.js";

let lastSearch = "";

export function Dashboard(props) {
  const pageSize = 5;
  
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  
  const [advanced, setAdvanced] = useState(false);

  
  const user = useAPI("/api/user", authContext);
  //const stats = useAPI("/api/demo/stats");
  const userSite = useAPI("/api/site/dashboard", authContext);
  const playXPConstant = useAPI("/api/var/play_xp_modifier", authContext);
  const creditDiscount = useAPI("/api/var/credit_discount");
  const showAd = useAPI("/api/var/show_ad");
  const currentUserPlayInfo = useAPI("/api/play_balance", authContext);
  const currentUserBalanceInfo = useAPI("/api/shop/credits", authContext);
  
  const XPList = useAPI("/api/application_xp");

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }

  //const progress = useAPI('/api/demo/progress');
  //const table = useAPI('/api/demo/users/list');
  //const userChart = useAPI('/api/demo/users/types');
  //const revenueChart = useAPI('/api/demo/revenue');
  //const markdown = useAPI("/api/markdown/dashboard/wallet");

  //const { active, account, chainId } = useWeb3React();
  const [nftBaseList, setNFTBaseList] = useState(null);
  const [nftList, setNFTList] = useState(null);
  const [recentNFTList, setRecentNFTList] = useState(null);
  
  const [nftEvents, setNFTEvents] = useState(null);
  const [nftCollections, setNFTCollections] = useState(null);
  const [recentEvent, setRecentEvent] = useState(null);
  const [todayXP, setTodayXP] = useState(0);
  const [todayApplications, setTodayApplications] = useState(0);
  const [userLevelInfo, setUserLevelInfo] = useState(null);
  const [rawUserLevelInfo, setRawUserLevelInfo] = useState(null);
  const [lastHourInfo, setLastHourInfo] = useState(null);
  const [referralURL, setReferralURL] = useState(null);
  //const [hasActiveNFTs, setHasActiveNFTs] = useState(false);
  //const [nftImages, setNFTImages] = useState(null);
  //const [defaultNFTImageIndex, setDefaultNFTIndex] = useState(0);
  const [existingAccount, setAccount] = useState(null);
  //const [userHasSolanaWallet, setUserHasSolanaWallet] = useState(null);
  //const [userSolanaWallet, setUserSolanaWallet] = useState(null);

  const [foundTwitterProfile, setFoundTwitterProfile] = useState(false);
  const [search, setSearch] = useState("");
  //const [currentSolanaKey, setCurrentSolanaKey] = useState(null);
  const [userXPModifier, setCurrentUserXPModifier] = useState(1);
  const [currentPlayInfo, setCurrentPlayInfo] = useState(null);

  const [showRewards, setShowRewards] = useState(true);
  const [showRecruit, setShowRecruit] = useState(true);
  const [showCollectibles, setShowCollectibles] = useState(false);
  
  const [showStats, setShowStats] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);

  const [currentUserCreditInfo, setCurrentUserCreditInfo] = useState(null);
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [hideUnlock, setHideUnlock] = useState(false);

  //Solana Wallet
  //const { publicKey, sendTransaction } = useWallet();
  //const solanaTimerRef = useRef(null);
  localStorage.removeItem('space_id');
  localStorage.removeItem('account_link');
  localStorage.removeItem('spaces');
  localStorage.removeItem('new_community');

  function formatNumber(value) {
    if (typeof value !== 'number' || isNaN(value)) {
      return value;
      //throw new Error('Invalid input. Please provide a valid number.');
    }
  
    if (value >= 1000000) {
      return (value / 1000000).toFixed(2) + 'M';
      //return parseInt((value / 1000000)) + 'M';
    } else if (value >= 10000) {
      return (value / 1000).toFixed(2) + 'K';
      //return parseInt((value / 1000)) + 'K';
    } else {
      return value.toLocaleString();
    }
  }

  async function viewNFT2(nft) {
    console.log(nft);
    let traits = nft.traits;
    if(!traits){
      traits = [{trait_type: "Traits", value: "None"}]
    }
    const stringOutput = traits.map(trait => `${trait.trait_type}: ${trait.value}`).join('<br/>');
    viewContext.modal.show(
      {
        title: "Collectible Info",
        buttonText: "OK",
        destructive: false,
        //preText: "Changes: " + res.tokenChanges,
        text: stringOutput,
        //text2: "Don't forget to update your profile pictures on Twitter, etc!",
        //image: res.image,
      },
      () => {}
    ); //modal
  }

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function selectNFT(nft) {
    const updatedSelectedNFTs = [...selectedNFTs];
  
    const index = updatedSelectedNFTs.findIndex(item => item.id === nft.id);
    if (index !== -1) {
      // NFT already exists in the selectedNFTs array, remove it
      updatedSelectedNFTs.splice(index, 1);
      nft.selected = false;
    } else {
      // NFT doesn't exist, add it with selected = true
      nft.selected = true;
      updatedSelectedNFTs.push(nft);
    }
  
    setSelectedNFTs(updatedSelectedNFTs);
  }

  async function deactivateNFTs() {
    setHideUnlock(true);
    setProcessing(true); // Enable processing state

    //console.log(nft);
    //console.log(account);
    console.log("deactivateNFTs");
    console.log(selectedNFTs);
    for (const nft of selectedNFTs) {
      try {
        //console.log(nft);
        
        /*let contract_address = nft.collectionAddress || nft.collection.address;
        let token_id = nft.token_id;
        let nft_pic = nft.image_url;
        let chain = nft.chain;
        */
        const res = await Axios.post('/api/nft/ntrack', { 
          id: nft.id
        });
        
        console.log(res.data);
        if(res.data.success){
          const updatedSelectedNFTs = [...selectedNFTs];
          let thisNFT = updatedSelectedNFTs.filter(item => item.id === nft.id);
          console.log(thisNFT);
          thisNFT[0].deactivated = true;
          thisNFT[0].selected = false;
          console.log(`NFT ${nft.id} processed successfully`);
        }
        
        
        //setSelectedNFTs(updatedSelectedNFTs);
        await delay(500); // Delay of 500 milliseconds

        
        
      } catch (error) {
        console.error(`Error processing NFT ${nft.index}:`, error);
      }
      
    }

    
    
    setSelectedNFTs([]);
    setProcessing(false); // Disable processing state
    setHideUnlock(false);

    viewContext.notification.show("Collectibles Deactivated!", 'success', true);
      
  };
  


  useEffect(() => {
   

    if (localStorage.getItem("showRecruit") !== null) {
      let _showRecruit = localStorage.getItem("showRecruit");
      //console.log(_showRecruit);
      setShowRecruit(!_showRecruit);
    }

    if (localStorage.getItem("showStats") !== null) {
      let _showStats = localStorage.getItem("showStats");
      //console.log(_showStats);
      setShowStats(!_showStats);
    }
  }, []);

  useEffect(() => {
    const storage = (event) => {
      console.log(event);
      if(event.originalEvent.storageArea===sessionStorage){
         //const data = getUserId();
         //if(data) setUserId(data);
      } 
    };
    window.addEventListener("storage", storage);
    return ()=> window.removeEventListener("storage", storage)
  }, []);

  useEffect(() => {
    //console.log(viewContext);
    if(viewContext.isAdvanced){
      setShowRewards(true);
      setShowRecruit(true);
      setAdvanced(true);
    } else {
      setShowRewards(false);
      setShowRecruit(false);
      setAdvanced(false);
    }
  }, [viewContext]);

  function truncateString(theString, truncLength = 50) {
    let retString = theString;
    if (theString.length > truncLength) {
      let strLen = theString.length;
      let string1 = theString.substring(0, truncLength);
      retString = string1 + "...";
    }
    return retString;
  }


  async function showHideRecruit() {
    //console.log(showRecruit)
    let _showRecruit = !showRecruit;
    localStorage.setItem("showRecruit", _showRecruit);
    setShowRecruit(_showRecruit);
  }

  async function showHideStats() {
    //console.log(showStats)
    let _showStats = !showStats;
    localStorage.setItem("showStats", _showStats);
    setShowStats(_showStats);
  }

  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "K", "M", "B","T"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
}

  

  useEffect(() => {
    if (userSite?.loading) {
    } else {
      //console.log(userSite);
      if (userSite.data) {
        if (userSite.data.message) {
          //console.log(userSite.data.message);
          viewContext.notification.show(userSite.data.message, "success", false);
        }
      }
    }
  }, [userSite]);

  useEffect(() => {
    if (XPList?.loading) {
      //console.log(XPList);
    } else {
      //console.log(XPList);
    }
  }, [XPList]);

 

  useEffect(() => {
    if (nftBaseList) {
      //console.log(nftBaseList);
      if (search !== "" || lastSearch.toLowerCase() !== search.toLowerCase()) {
        if (lastSearch.toLowerCase() !== search.toLowerCase()) {
          //console.log(search);
          let list = nftBaseList;
          //console.log(list);
          let thisSearch = search.toLowerCase();
          let shortList = list.filter(function (nft) {
            //console.log(creator);
            return (
              nft.name.toLowerCase().indexOf(thisSearch) !== -1 ||
              (nft.collection_name &&
                nft.collection_name.toLowerCase().indexOf(thisSearch) !== -1) ||
              (nft.nickname &&
                nft.nickname.toLowerCase().indexOf(thisSearch) !== -1)
            );
          });

          lastSearch = thisSearch;
          setNFTList(shortList);
        }
      }
    }
  }, [search, nftBaseList]);

  

  useEffect(() => {
    if (playXPConstant?.loading) {
      //console.log(playXPConstant);
    } else {
      //console.log(playXPConstant);
    }
  }, [playXPConstant]);

  const getNFTsNEW = useCallback(async () => {
    const res = await Axios.post("/api/nft/user_list", {
      //chain: "eth",
      //account: account,
      //publisher_id: publisher.id,
      //nft_template_id: nftFilter,
      //chainId: chainId,
      simple: true,
      filter: "unlocked",
    });

    let thisNFTList = res.data.data.nfts;
    let nftCollections = res.data.data.collections;

    //console.log(res.data);
    /*const profileRes = await Axios.get("/api/nft/checkProfileHashes");
    //console.log(profileRes.data);

    //setHasActiveNFTs(false);
    let profileNFTs = profileRes.data.data;
    
    let foundATwitterProfile = false;
    //console.log(profileNFTs);

    //console.log(thisNFTList);

    thisNFTList.forEach((nft) => {
      //console.log(nft);
      nft.badge = null;

      if (profileNFTs.twitter) {
        let nft_id = nft.nft_id.toLowerCase();
        let profile_nft_id = profileNFTs.twitter.id;
        if (profile_nft_id === undefined) {
          profile_nft_id = profileNFTs.twitter.nft_id;
        }
        profile_nft_id = profile_nft_id.toLowerCase();

        if (nft_id === profile_nft_id) {
          //console.log("Twitter Match");
          nft.badge = "twitter";
          foundATwitterProfile = true;
        }

        //console.log("Has a Twitter Match");
      }
    });
    */

    let tmpRecentNFTList = thisNFTList.filter(nft=>(nft.todayInscribed > 0 || nft.todayXPApplications || nft.recentXPApplications || nft.days_activated < 30));
    if(tmpRecentNFTList && tmpRecentNFTList.length){
      setRecentNFTList(tmpRecentNFTList);
    }

    //console.log(profileNFTs);
    //console.log(thisNFTList.length);
    let maxPageNum = Math.floor(thisNFTList.length / pageSize);
    //console.log("maxPageNum");
    //console.log(maxPageNum);
    setMaxPageIndex(maxPageNum - 1);
    setPageIndex(0);
    setFoundTwitterProfile(false);
    setTodayApplications(res.data.data.applications);
    setNFTBaseList(thisNFTList);
    setNFTList(thisNFTList);
    setNFTEvents(res.data.data.events);
    setNFTCollections(nftCollections);
    setTodayXP(res.data.data.todayXP);
    

    if (res.data.data.events.length) {
      //console.log(res.data.data.events[0]);

      setRecentEvent(res.data.data.events[0]);
    }
  }, []);

  useEffect(() => {
    if(authContext.user){
      getNFTsNEW();
    }
    
  }, []);

  useEffect(() => {
    if (currentUserBalanceInfo?.loading) {
      //console.log(currentUserBalanceInfo);
    } else {
      //console.log(currentUserBalanceInfo);
    }
  }, [currentUserBalanceInfo]);

  useEffect(() => {
    if (currentUserPlayInfo?.loading) {
      //console.log(currentUserPlayInfo);
    } else {
      //console.log(currentUserPlayInfo);
      if (currentUserPlayInfo.success) {
        //console.log(currentUserPlayInfo);
        if (playXPConstant.success) {
          if (currentUserPlayInfo.data >= 500) {
            //console.log(playXPConstant);
            let theModifier = playXPConstant.data * currentUserPlayInfo.data;
            theModifier = theModifier.toFixed(2);
            //console.log(theModifier);
            setCurrentUserXPModifier("+" + theModifier.toLocaleString() + "%");
          } else {
            setCurrentUserXPModifier("0\r\n(500 Minimum)");
          }
        }
      }
    }
  }, [currentUserPlayInfo, playXPConstant]);

  

  /*
  async function getNFTs(){
          
    //let nftFilter=[];

    const res = await Axios.post('/api/nft/user_list', { 
      //chain: "eth",
      account: account,
      //publisher_id: publisher.id,
      //nft_template_id: nftFilter,
      chainId: chainId,
      filter: 'unlocked'
    });
    const profileRes = await Axios.get('/api/nft/checkProfileHashes');
    //console.log(profileRes.data);
    
    //console.log(res.data);
    //console.log(profileRes.data);
    //setHasActiveNFTs(false);
    let profileNFTs = profileRes.data.data;
    let thisNFTList = res.data.data.nfts;
    let foundATwitterProfile = false;
    
    thisNFTList.forEach(nft => {
      //console.log(nft);
      nft.badge = null;

      if(profileNFTs.twitter){
        //console.log("Has a Twitter Match");
        if(nft.nft_id === profileNFTs.twitter.id){
          //console.log("Twitter Match");
          nft.badge = "twitter"
          foundATwitterProfile = true;
        }
      }
    });


    //console.log(profileNFTs);
    //console.log(thisNFTList);
    setFoundTwitterProfile(foundATwitterProfile);
    setNFTList(thisNFTList);
    setNFTEvents(res.data.data.events);
    setTodayXP(res.data.data.todayXP);

    if(res.data.data.events.length){
      //console.log(res.data.data.events[0]);
      
      setRecentEvent(res.data.data.events[0]);
    }
  }
  */

  

  useEffect(() => {
    async function getUserCredits() {
      if (user.data) {
        const resCredits = await Axios.get(`/api/shop/credits`);
        //console.log(resCredits.data.data);
        setCurrentUserCreditInfo(resCredits.data.data);
      }
    }
    async function getUserLevel() {
      if (user.data) {
        const res = await Axios.post("/api/user/profile_stats", {
          user_id: user.data.id
        });


        //console.log(res.data.data);
        //console.log(res.data);
        if(res.data.data && res.data.data.xpInfo){
          setUserLevelInfo(res.data.data.xpInfo);
        }
        

      }
    }
    async function getUserEvents() {
      if (user.data) {
        const res = await Axios.post("/api/user/events", {
          user_id: user.data.id,
          truncate_events: true,
        });

        if (user.data.referral_url) {
          setReferralURL(user.data.referral_url.toLowerCase());
        }

        console.log(res.data.data);
        //console.log(res.data);
        //setUserLevelInfo(res.data.data.level);
        setRawUserLevelInfo(res.data.data.level);
        setLastHourInfo(res.data.data.lastHour);


      }
    }

    getUserLevel();
    getUserEvents();
    getUserCredits();

  }, [user]);

  /*
  useEffect(() => {
    //console.log(user);
    
    //user?.data?.referral_url && console.log(user.data.referral_url);

    //console.log(chainId);
    if(active){
      //console.log("active");
      async function changeAccount(){
        //console.log("Account changed");
        setAccount(account);
      }

      //console.log("CHAIN: " + chainId);

      if(existingAccount !== account){
        changeAccount();
        getNFTsNEW();
      }
    } else {
      //console.log("Not active");
      setNFTList(null);
      setAccount(null);
    }
    
  },[existingAccount, setAccount, account, active, nftList, chainId]);
  */

  async function changeNFTPage(chg) {
    let curPage = pageIndex;

    curPage = curPage + chg;
    if (curPage < 0) {
      curPage = 0;
    }
    if (curPage > maxPageIndex) {
      curPage = maxPageIndex;
    }
    setPageIndex(curPage);
  }

  async function activateNFT(nft) {
    //console.log(nft);
    //console.log(account);
    let contract_address = nft.asset_contract.address;
    let token_id = nft.token_id;
    let nft_pic = nft.image_url;
    let chain = nft.chain;
    /*const res = await Axios.post("/api/nft/track", {
      contract_address: contract_address,
      token_id: token_id,
      nft_pic: nft_pic,
      chain: chain,
      wallet_address: account,
    });
    //console.log(res.data);
    getNFTsNEW();
    */
  }

  async function viewNFT(nft) {
    //console.log(nft);
    //let navPath = `/nft/holder?id=${nft.nft_id}`;
    let navPath = `/nft/holder/${nft.nft_id}`;
    //console.log(navPath);
    //return <Navigate to={navPath}/>;
    navigate(navPath);
    //console.log(account);
    /*
    let contract_address = nft.asset_contract.address;
    let token_id = nft.token_id;
    let nft_pic = nft.image_url;

    const res = await Axios.post('/api/nft/track', { 
      contract_address: contract_address,
      token_id: token_id,
      nft_pic: nft_pic,
      wallet_address: account
    });
    console.log(res.data);
    */
  }

  function showAllActivated() {
    setShowCollectibles(!showCollectibles);
  }

  function changePrivacy() {
    viewContext.modal.show(
      {
        title: "Change Privacy",
        form: {
          show_username: {
            type: "radio",
            options: ["Yes", "No"],
            required: true,
            errorMessage: "Please choose an option",
            default: user.data?.show_username ? "Yes" : "No",
            label:
              "Show your username on Collectibles you are tracking?",
          },
        },
        buttonText: "Set Privacy",
        url: "/api/user/show_username",
        method: "POST",
      },
      (re, data) => {
        if(data){
          //console.log(res);
          viewContext.notification.show(
            "User Privacy Updated!",
            "success",
            true,
            "toast",
            "heart"
          );
          setTimeout(() => navigate("/"), 2500);
        }
        
      }
    );
  }

  async function clearProfileCache() {
    //console.log(account);
    viewContext.modal.show(
      {
        title: "Clear Profile Cache",
        form: {},
        buttonText: "Clear",
        url: "/api/nft/rebuildImageCache",
        method: "POST",
        destructive: false,
        text: "This can help you get a better profile pic match by removing old results. Use if you're having trouble getting XP or to perform maintenance from time to time.",
      },
      (res, thing) => {
        //const res = await Axios.post('/api/nft/rebuildImageCache');
        viewContext.notification.show("Profile Cache Cleared", "success", true);
        setTimeout(() => navigate("/"), 1500);
      }
    );
  }

  //console.log("window.location.hostname : " + window.location.hostname);
  //if (props?.url?.includes('http')){
  //if (location.protocol !== 'https:') {
  //location.replace(`https:${location.href.substring(location.protocol.length)}`);
  //}

  if(!authContext.user){
    return (
      <Card dark700 center restrictWidthWide>
        <SignIn title="Signin Required" text="You must signin to use this feature" />
      </Card>


    );
  }

  return (

    
    <>
    {1===3 && 
    <Header>sss</Header>
    }
    
    {user && user.data && 
      <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
    }
    

    <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      {!creditDiscount.loading && creditDiscount.data !== 0 && (
        <Message
          messageID="Discount_Credits"
          title="Credit Discount Active"
          text={
            "Anything which costs credits (Inscribes, NFT Upgrades) is " +
            creditDiscount.data * 100 +
            "% off!"
          }
          type="success"
        />
      )}
      {user.data && !user.data.discord_id && 
          <Card restrictWidthWider center dark name="account_link" className={"text-white"}>
            <strong  className={"text-white center"}>Link your Social Accounts to BOOST your communities and earn more XP!
            {/*<br/>+2000XP Bonus when you link!*/}</strong>
            
              <div >
                <SocialSignin className='!max-w-xs' network={['discord']} accountlink localStorage={'account_link'} uid={user.data.id} normal text={'Link Discord Account'} />
                <p className={"text-white center"}>Earn XP when you engage, attend town halls and interact in voice channels.</p>  
              </div>
            
          </Card>
          }
      {/*<Message
        closable
        messageID="HowXP_1"
        title='How to Get XP'
        text="We have created a list of all the different ways you can earn XP with your Collectibles through games, apps, and bonuses."
        buttonLink="/xp"
        buttonText="Check it Out!"
        newTab
        type='success'
      />
    */}
      {/*<Message
        closable
        messageID="StreaksIntro_2"
        title="Site Streaks Rewards Now Active!"
        text="Users now earn Credits just for logging in every day. Earn Credits for daily, 3-day, 7-day, 30, 90, and 365 day Streaks!"
        type="success"
  />*/}
      
      {/*{nftList && nftList.length === 0 && Search === '' && (*/}
      
      {nftList && nftList.length > 0 && 1===2 && (
        <Message
          closable
          messageID="Message_Tweets_2"
          titleOLD="Notice: Tweet XP Change!"
          text="Boost the community's #rt4xp posts and earn XP!"
          buttonLink="/posts"
          buttonText="View Posts"
          type="success"
        />
      )}

      {user?.data?.email.includes('hasnoemail') && 
        <Message
          messageID="Message_NoEmail"
          title="No Account Email"
          text="Your account does not have a valid email associated with it. You will not receive email notifications or paid offers."
          buttonText="Update User Profile"
          buttonLink="/account/profile"
          type="error"
        />
      }

    {showRewards && (
        <>
      <Card name="rewards" center className={"bg-slate-200"}>
          <Grid cols="4">
            <div className={"mb-0"}>
            
              <strong>Metaverse Credits: </strong>{" "}
              {currentUserCreditInfo && currentUserCreditInfo.creditBalance
                ? `${currentUserCreditInfo.creditBalance.toLocaleString()}`
                : "--"}
              <br/>
              <Link
                title="Transaction History"
                target="_same"
                url="/shop/history"
                text="History"
              />
              &nbsp;&nbsp;&nbsp;
              <Link
                title="Need More Metaverse Credits? Get some here!"
                target="_blank"
                url="/shop/token/play"
                text="Get Credits"
              />
            
            </div>
            <div>
              <strong>$PLAY Balance: </strong>
              {currentUserPlayInfo.loading
                ? "--"
                : currentUserPlayInfo.data
                ? currentUserPlayInfo.data.toLocaleString()
                : "0"}
            </div>
            {/*<div><strong>Global $PLAY-to-XP Ratio:</strong><br/>{playXPConstant.loading ? '--' : playXPConstant.data}</div>*/}
            <div>
              <strong>$PLAY Bonus XP: </strong>
              {currentUserPlayInfo.loading ? "--" : userXPModifier}
            </div>
            <div>
              <Button
                tiny
                className={"float-left p-8 mx-2 ..."}
                url="https://play.nftscribe.io"
                text="Buy $PLAY"
                target="_new"
              />
            </div>
          </Grid>
        

      </Card>
      </>
      )}
      

      {showStats && (
        <>
          <Grid cols={advanced ? "3" : "3"}>
            
          {advanced && <Stat
              key="stat1"
              labelSmall
              loading={false}
              value={nftList?.length}
              label="Activated Collectibles"
              icon="users"
            />
          }
            
          {/*<Stat
            labelSmall
            loading={ stats?.loading }
            value={ todayXP.toLocaleString() }
            label='XP Earned Today'
            icon='check'
          />
          */}

            <Stat
              key="stat2"
              labelSmall
              loading={false}
              value={userLevelInfo ? userLevelInfo.level : rawUserLevelInfo ? rawUserLevelInfo.level :  "--"}
              label={"User Metaverse Level"}
              //description="This is your overall user Metaverse level, which includes all XP you've ever received"
              icon=""
            />
            <Stat
              key="stat3"
              labelSmall
              loading={false}
              label={"Metaverse XP/XP for Next Level"}
              value={
                userLevelInfo ? userLevelInfo.xp.toLocaleString() + "/" + userLevelInfo.nextLevelXP.toLocaleString() : 
                rawUserLevelInfo ? rawUserLevelInfo.xp.toLocaleString() + "/" + rawUserLevelInfo.nextLevelXP.toLocaleString() : "--"
              }
              icon=""
              //description="As you accumulate XP for activity, your total builds and you Level Up"
            />
            {!advanced && 
            <Stat
              key="stat4"
              labelSmall
              loading={false}
              label={"Metaverse Credits"}
              value=
                {currentUserCreditInfo && currentUserCreditInfo.creditBalance
                  ? `${currentUserCreditInfo.creditBalance.toLocaleString()}`
                  : "--"}
              
              icon=""
              //description="As you accumulate XP for activity, your total builds and you Level Up"
            />}

          </Grid>
          {/*
          <Grid cols="4">
            <Stat
            labelSmall        
            loading={ stats?.loading }
            value={ recentEvent ? recentEvent.event_description: '--'}
            label={ 'Last Event: ' + (recentEvent ? new Date(recentEvent.date_created).toUTCString() : '--')}
            icon=''
          />
        
            <Stat
              labelSmall
              loading={false}
              label={"Global Scribe XP Events (Last Hour)"}
              value={
                lastHourInfo ? lastHourInfo.event_count.toLocaleString() : "--"
              }
              icon=""
            />
            <Stat
              labelSmall
              loading={false}
              label={"Global Scribe XP (Last Hour)"}
              value={
                lastHourInfo
                  ? isNaN(parseInt(lastHourInfo.event_xp))
                    ? 0
                    : parseInt(lastHourInfo.event_xp).toLocaleString()
                  : "--"
              }
              icon=""
            />
            <Stat
              labelSmall
              loading={false}
              label={"Users Online (Last Hour)"}
              value={
                lastHourInfo ? lastHourInfo.users_online.toLocaleString() : "--"
              }
              icon=""
            />
          </Grid>
            */}
        </>
      )}
      

      
        
      {nftList && (
        <>
          {nftList && nftList.length > 0 && !foundTwitterProfile && (1===2) && (
            <Message
              text="Your profile pic does not seem to match any of your Activated Collectibles. Please use your exact Collectible image if you'd like to have it tracked."
              type="warning"
            />
          )}

          
          <Card dark name="dashboard_profile" center className='mt-0'>
            {!isMobile && advanced  && (
              <Grid cols="6" key="buttons">
                {/*<Button key="manual" small color={"blue"} text='Track Space' action={ addManualSpace }/>*/}
                <Button
                  key="privacy"
                  small
                  color={"blue"}
                  className={"py-1"}
                  text="Privacy"
                  action={changePrivacy}
                />
                <Button
                  key="ImageCache"
                  small
                  color="blue"
                  className={"py-1"}
                  text="Clear Profile Cache"
                  action={(e) => clearProfileCache()}
                />
                {selectedNFTs.length > 0 && 
                <Button 
                  small
                  className={ Style.nftButton }
                  processing={processing}
                  color={"green"}
                  
                  action={ e => deactivateNFTs() }
                  text={"Deactivate Selected" }
                />

                }
              </Grid>
            )}

            {nftList && nftList.length === 0 && (
              <Message
                messageID="Message_NoNFTs"
                title="Welcome to Scribe!"
                text="Your Activated Collectibles show up here. To Activate some of your Collectibles and begin earning XP, visit MyStuff."
                buttonText="MyStuff"
                buttonLink="/collectibles"
                type="info"
              />
            )}

            {isMobile && (
              <div className={"float-clear"}>
                {pageIndex > 0 && (
                  <Button
                    key="prevPage"
                    small
                    className={"float-left py-1"}
                    color={"blue"}
                    text={`Previous Page: ${pageIndex + 1}`}
                    action={(e) => changeNFTPage(-1)}
                  />
                )}
                {pageIndex < maxPageIndex && (
                  <Button
                    key="nextPage"
                    small
                    className={"float-right py-1"}
                    color={"blue"}
                    text={`Next Page: ${pageIndex + 2}`}
                    action={(e) => changeNFTPage(1)}
                  />
                )}
                <br />
                <br />
              </div>
            )}

            {recentNFTList && 
              <>
        
        
                <Card dark name='wallets' center key='wallets_loading'>
                  <span>These are your collectibles with recent tracked activity (30 days).</span>
                  <div className='float-right'>
                    <Button
                      tiny
                      id='centerButton'
                      key='centerButton'
                      color={ props.destructive ? 'red' : 'green' }
                      text={ "History" }
                      goto={'/activity'}
                      //action={ () => navigate(-1) }
                    />
                  </div>
                </Card>
        
          
                <Grid cols={ advanced?"5": "4"} >
                {
                  recentNFTList.filter(x=>(!x.deactivated)).map((nft, index) => {
                    let pageMin = pageIndex * pageSize;
                    let pageMax = pageMin + pageSize;
                    if (isMobile) {
                      if (index < pageMin || index >= pageMax) {
                        return null;
                      }
                    }

                    let unAssignedXP = nft.uninscribedTotalXP.toLocaleString();
                    //let XP = (nft.xp_raw - nft.xp).toLocaleString();

                    //console.log(unAssignedXP);

                    return (
                      nft.image_url !== null && (
                        <Card
                          shadow
                          rounded
                          nobadge={nft.badge}
                          badgeText={nft.badge}
                          badgeColor={"blue"}
                          brandIcon={nft.badge}
                          brandText={nft.badge}
                          brandColor={"blue"}
                          key={nft.id}
                          id={nft.id}
                          className={Style.nftContainer}
                          sideBadges={nft.buckets}
                          topBadge={nft.todayInscribed > 0 ? "Leveled Up to " + nft.todayInscribed + "!" : ""}
                          ulBadge={nft.chain}
                          center
                          interactive
                          action={(e) => {
                            viewNFT(nft);
                          }}
                        >
                          <div key="collname" className={Style.collection}>
                            {nft.collection_name}
                          </div>

                          <div key="nftname" className={Style.nftname}>{nft.name}</div>
                          <div id="divTokenID" className={ Style.nftname }>
                            Token #{ truncateString(nft.token_id, 25) }
                          </div>

                          <div key="nickname" className={Style.nftname}>
                            {nft.nickname ? (
                              "Name: " + nft.nickname + " "
                            ) : (
                              <>&nbsp;</>
                            )}
                            {nft.title ? "'" + nft.title + "'" : <>&nbsp;</>}
                          </div>
                          

                          <Image
                            className={Style.nftImage}
                            nftImage={true}
                            border={true}
                            loading="lazy"
                            source={nft.image_url}
                            alt={nft.token_name}
                            interactive
                            key={nft.id + "_image"}
                            action={(e) => {
                              viewNFT(nft);
                            }}
                          />

                          <>
                            <ProgressBar
                              small
                              progress={nft.progress + "%"}
                              key={nft.id + "_progress"}
                              label={
                                nft.unlocked === 1
                                  ? `Level: ${
                                      nft.xpStats.level
                                    }  (${formatNumber(nft.xp)} XP of ${formatNumber(nft.xpStats.nextLevelXP)} XP)`
                                  : "Activate to Level Up"
                              }
                            />

                            {/*unAssignedXP !== "0" && 
                              <><ProgressBar
                              raw
                              small
                              progress={
                                unAssignedXP !== "0"
                                  ? nft.progress_raw + "%"
                                  : "0"
                              }
                              label={
                                nft.unlocked === 1
                                  ? unAssignedXP === "0"
                                    ? "(No Uninscribed XP)"
                                    : //`Uninscribed XP: ${unAssignedXP} Level: ${nft.xpStatsRaw.level} (${nft.xp_raw.toLocaleString()} of ${nft.xpStatsRaw.nextLevelXP.toLocaleString()})`
                                      `Uninscribed XP: ${unAssignedXP}`
                                  : "Activate to Level Up"
                              }
                              //label={nft.unlocked === 1 ? `Uninscribed XP: ${unAssignedXP} ${unAssignedXP > 0 ? `Level ${nft.xpRawStats.level}` : ''}` : 'Activate to Level Up'}
                            /></>
                            */}
                            {!hideUnlock && !nft.deactivated && 
                              <div className="flow-root">  
                                <Button 
                                  className={ Style.nftButtonLeft }
                                  tiny
                                  color={nft.selected ? "red" : "green" }
                                  variant="contained"
                                  action={ e => selectNFT(nft) }
                                  text={ nft.selected ? "Unselect" : "Select" }
                                />
                                <Button 
                                  className={ Style.nftButtonRight }
                                  tiny
                                  color={"blue" }
                                  variant="contained"
                                  action={ e => viewNFT2(nft) }
                                  text={ "Traits" }
                                />
                              </div>
                            }

                            {nft.todayXP !== 0 && 
                              <div className='-mb-2 -mt-1'>
                                <small>XP Today:
                                <span className={Style.greenText}>
                                  &nbsp;+{nft.todayXP.toLocaleString()}<br/>
                                </span></small>
                                
                                <div className={Style.applicationDiv}>
                                  {nft.todayXPApplications.map((nftApplication, index) => {
                                    //console.log(todayApplications);
                                    //console.log(nftApplication);
                                    let appImage = todayApplications.filter(y=>y.id === nftApplication.application_id)[0];
                                    //console.log(appImage);
                                    return (
                                      <div key={`apps_${index}`} className={Style.applicationImageDiv}>
                                        <Image
                                          className={Style.nftImageTiny}
                                          nftImage={true}
                                          border={false}
                                          source={appImage.logo_square_url}
                                        />
                                        <span className={Style.tinyTextSpan}>
                                          <small className={Style.tinyText}>+{abbreviateNumber(nftApplication.total_xp)}</small>
                                        </span>
                                        
                                      </div>
                                    )
                                  })}
                                  <br/>
                                </div>
                              </div>}

                            
                            
                          </>

                          

                          {/*<Button
                            className={Style.nftButton}
                            tiny
                            color={nft.unlocked === 1 ? "green" : "blue"}
                            variant="contained"
                            action={(e) =>
                              nft.unlocked === 1 ? viewNFT(nft) : activateNFT(nft)
                            }
                            text={nft.unlocked === 1 ? "Manage" : "Activate"}
                          />*/}
                        </Card>
                      )
                    );
                  }) // return
                }
              </Grid>
            </>
            } {/*Recent NFTs*/}
            {nftList.length > 0 && !recentNFTList && (
              <Card dark>
                  <Message
                    messageID="Message_NoActiveNFTs"
                    title="No Active Collectibles!"
                    text="Your Active Collectibles which have earned XP recently (30 days or so) will show up here."
                    type="info"
                  />
              </Card>
            )}

            {nftList.length > 0 &&
              <Button
                key="showCollectibles"
                small
                color={"blue"}
                className={"py-1"}
                text={showCollectibles ? "Hide Others" : "Show All Activated"}
                action={showAllActivated}
              />
              }
            </Card>
            
            { (advanced || showCollectibles) && 
            <Card title="Activated Collectibles" dark name="dashboard_profile1" center>
              <Search throttle={1000} callback={(x) => setSearch(x)} />
              <br />
            <Grid cols={ advanced?"5": "4"} >
              {
                nftList.filter(nft=>(!nft.deactivated && nft.todayInscribed <= 0 &&  !nft.todayXPApplications)).map((nft, index) => {
                  let pageMin = pageIndex * pageSize;
                  let pageMax = pageMin + pageSize;
                  if (isMobile) {
                    if (index < pageMin || index >= pageMax) {
                      return null;
                    }
                  }

                  let unAssignedXP = nft.uninscribedTotalXP.toLocaleString();
                  //let XP = (nft.xp_raw - nft.xp).toLocaleString();

                  //console.log(unAssignedXP);

                  return (
                    nft.image_url !== null && (
                      <Card
                        shadow
                        rounded
                        nobadge={nft.badge}
                        badgeText={nft.badge}
                        badgeColor={"blue"}
                        brandIcon={nft.badge}
                        brandText={nft.badge}
                        brandColor={"blue"}
                        key={nft.id}
                        id={nft.id}
                        className={Style.nftContainerNormal}
                        sideBadges={nft.buckets}
                        topBadge={nft.todayInscribed > 0 ? "Leveled Up to " + nft.todayInscribed + "!" : ""}
                        ulBadge={nft.chain}
                        interactive
                        action={(e) => {
                          viewNFT(nft);
                        }}
                        center
                      >
                        <div key="collname" className={Style.collection}>
                          {nft.collection_name}
                        </div>

                        <div key="nftname" className={Style.nftname}>{nft.name}</div>
                        <div id="divTokenID" className={ Style.nftname }>
                            Token #{ truncateString(nft.token_id, 25) }
                          </div>

                        <div key="nickname" className={Style.nftname}>
                          {nft.nickname ? (
                            "Name: " + nft.nickname + " "
                          ) : (
                            <>&nbsp;</>
                          )}
                          {nft.title ? "'" + nft.title + "'" : <>&nbsp;</>}
                        </div>

                        <Image
                          className={Style.nftImage}
                          nftImage={true}
                          border={true}
                          loading="lazy"
                          source={nft.image_url}
                          alt={nft.token_name}
                          interactive
                          key={nft.id + "_image"}
                          action={(e) => {
                            viewNFT(nft);
                          }}
                        />

                        <>
                          <ProgressBar
                            small
                            progress={nft.progress + "%"}
                            key={nft.id + "_progress"}
                            label={
                              nft.unlocked === 1
                                ? `Level: ${
                                    nft.xpStats.level
                                  }  (${formatNumber(nft.xp)} XP of ${formatNumber(nft.xpStats.nextLevelXP)} XP)`
                                : "Activate to Level Up"
                            }
                          />

                            {!hideUnlock && !nft.deactivated && 
                              <div className="flow-root">  
                                <Button 
                                  className={ Style.nftButtonLeft }
                                  tiny
                                  color={nft.selected ? "red" : "green" }
                                  variant="contained"
                                  action={ e => selectNFT(nft) }
                                  text={ nft.selected ? "Unselect" : "Select" }
                                />
                                <Button 
                                  className={ Style.nftButtonRight }
                                  tiny
                                  color={"blue" }
                                  variant="contained"
                                  action={ e => viewNFT2(nft) }
                                  text={ "Traits" }
                                />
                              </div>
                            }

                          {/*unAssignedXP !== "0" && 
                            <><ProgressBar
                            raw
                            small
                            progress={
                              unAssignedXP !== "0"
                                ? nft.progress_raw + "%"
                                : "0"
                            }
                            label={
                              nft.unlocked === 1
                                ? unAssignedXP === "0"
                                  ? "(No Uninscribed XP)"
                                  : //`Uninscribed XP: ${unAssignedXP} Level: ${nft.xpStatsRaw.level} (${nft.xp_raw.toLocaleString()} of ${nft.xpStatsRaw.nextLevelXP.toLocaleString()})`
                                    `Uninscribed XP: ${unAssignedXP}`
                                : "Activate to Level Up"
                            }
                            //label={nft.unlocked === 1 ? `Uninscribed XP: ${unAssignedXP} ${unAssignedXP > 0 ? `Level ${nft.xpRawStats.level}` : ''}` : 'Activate to Level Up'}
                          /></>
                          */}

                          {nft.todayXP !== 0 && <>
                          <small>XP Today:
                          <span className={Style.greenText}>
                            &nbsp;+{nft.todayXP.toLocaleString()}<br/>
                          </span></small>
                          
                          <div className={Style.applicationDiv}>
                            {nft.todayXPApplications.map((nftApplication, index) => {
                              //console.log(todayApplications);
                              //console.log(nftApplication);
                              let appImage = todayApplications.filter(y=>y.id === nftApplication.application_id)[0];
                              //console.log(appImage);
                              return (<>
                                <div key={`apps_${index}`} className={Style.applicationImageDiv}>
                                  <Image
                                    className={Style.nftImageTiny}
                                    nftImage={true}
                                    border={false}
                                    source={appImage.logo_square_url}
                                  />
                                  <span className={Style.tinyTextSpan}>
                                    <small className={Style.tinyText}>+{abbreviateNumber(nftApplication.total_xp)}</small>
                                  </span>
                                  
                                </div>
                              </>)
                            })}
                            <br/>
                          </div>
                          </>}

                          
                          
                        </>

                        

                        {/*<Button
                          className={Style.nftButton}
                          tiny
                          color={nft.unlocked === 1 ? "green" : "blue"}
                          variant="contained"
                          action={(e) =>
                            nft.unlocked === 1 ? viewNFT(nft) : activateNFT(nft)
                          }
                          text={nft.unlocked === 1 ? "Manage" : "Activate"}
                        />*/}
                      </Card>
                    )
                  );
                }) // return
              }
            </Grid>
          </Card>
          
          }
          {isMobile && advanced && (
            <div className={"float-clear"}>
              {pageIndex > 0 && (
                <Button
                  key="prevPage"
                  small
                  className={"float-left py-1"}
                  color={"blue"}
                  text={`Previous Page: ${pageIndex + 1}`}
                  action={(e) => changeNFTPage(-1)}
                />
              )}
              {pageIndex < maxPageIndex && (
                <Button
                  key="nextPage"
                  small
                  className={"float-right py-1"}
                  color={"blue"}
                  text={`Next Page: ${pageIndex + 2}`}
                  action={(e) => changeNFTPage(1)}
                />
              )}
              <br />
              <br />
            </div>
          )}
        </>
      )}
      {isMobile && advanced && (
        <Grid cols="6" key="buttons">
          {/*<Button key="manual" small color={"blue"} text='Track Space' action={ addManualSpace }/>*/}
          <Button
            key="privacy"
            small
            color={"blue"}
            className={"py-1"}
            text="Privacy"
            action={changePrivacy}
          />
          <Button
            key="ImageCache"
            small
            color="blue"
            className={"py-1"}
            text="Clear Profile Cache"
            action={(e) => clearProfileCache()}
          />
        </Grid>
      )}

      {/*
      <Card name='revenues' title='Revenue'>
        <Chart
          type='line'
          legend
          loading={ revenueChart.loading }
          data={ revenueChart.data }
          color={['red', 'blue']}
        />
      </Card>

      <Grid cols='2'>
        <Card title='Goals' loading={ progress.loading }>
          { progress?.data?.map(item => {

            return (
              <ProgressBar
                key={ item.label }
                label={ item.label }
                progress={ item.value }
              />
            );

          })}
        </Card>
        <Card title='User Types'>
          <Chart
            type='pie'
            legend={ true }
            data={ userChart.data }
            loading={ userChart.loading }
            color='purple'
          />
        </Card>
      </Grid>

      {/*<Card title='Users' last>
        <Table
          search
          data={ table.data }
          loading={ table.loading }
          badge={{ col: 'plan', color: 'blue' }}>
        </Table>
      </Card>
        */}
        {showRecruit && (
        <Button
          key="showHideRecruit2"
          quicklinksmall
          color={"blue"}
          text="Hide Recruit"
          action={(e) => {
            showHideRecruit();
          }}
        />
      )}
      {referralURL != null && showRecruit && (
        <Card name="referral" center>
          <Grid cols="3">
            <div>
              <span>
                <strong>
                  Recruit users to gain XP as they do, +1000XP bonus when they
                  hit Level 2!
                  <br />
                  Referral Link:{" "}
                </strong>
                <Link
                  url={referralURL}
                  text={referralURL}
                  className={Style.link}
                  target="_new"
                  color="dark"
                />
              </span>
            </div>
            <div>
              <SocialShare
                url={referralURL}
                description="Want to get XP and level up your Collectibles like I am? Join me on Scribe!"
              />
            </div>
          </Grid>
        </Card>
      )}
      </div>
      <Feedback />
    </>
  );
}
