import { React, useMemo, useState, useEffect } from "react";
import Axios from 'axios';

// components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { View } from 'components/lib';
//import { loadStripe } from '@stripe/stripe-js';
//import { Elements } from '@stripe/react-stripe-js';

import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import Client from "@walletconnect/sign-client";
import QRCodeModal from "@walletconnect/qrcode-modal";


// 404
import { NotFound } from 'views/error/404';

// tailwind css
import '../css/output.css';

const chains = [
  'stacks:1',
  'stacks:2147483648',
];

// settings
//const Settings = require('settings.json');
//const StripePromise = loadStripe(Settings[process.env.NODE_ENV].stripe.publishableAPIKey);


require('@solana/wallet-adapter-react-ui/styles.css');

const routes = [

  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/website').default,
  ...require('routes/nft').default,
  ...require('routes/developer').default,
  ...require('routes/spaces').default,
  ...require('routes/leaderboard').default,
  ...require('routes/projects').default,
  ...require('routes/shop').default,
  ...require('routes/posts').default,
  ...require('routes/communities').default,
]



export default function App(props){

  const solNetwork = WalletAdapterNetwork.Mainnet;
  //const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork]);
  //
  const [ client, setClient ] = useState(undefined);
  const [ chain, setChain ] = useState(undefined);
  const [ session, setSession ] = useState(undefined);
  const handleConnect = async (chain) => {
    //setChain(undefined);
    //setChain(chain);
    console.log("CHAIN: " + chain);
    setChain(undefined);

    const { uri, approval } = await client.connect({
        pairingTopic: undefined,
        requiredNamespaces: {
            "stacks":{
            "methods":[
                'stacks_signMessage',
                'stacks_stxTransfer',
                'stacks_contractCall',
                'stacks_contractDeploy',
            ],
            "chains":[
                chain
            ],
            "events":[]
            }
        },
    });

    if (uri) {
    QRCodeModal.open(uri, () => {
        console.log("QR Code Modal closed");
    });
    }

    const session = await approval();
    setSession(session);
    setChain(chain);

    QRCodeModal.close();
  };

  const endpoint = "https://divine-spring-night.solana-mainnet.quiknode.pro/e464d54ffc705a38089484587a4b93af887d46cd/";
  const wallets = useMemo(
    () => [
        new PhantomWalletAdapter(),
        new GlowWalletAdapter(),
        new SlopeWalletAdapter(),
        new SolflareWalletAdapter({ solNetwork }),
        new TorusWalletAdapter(),
        new LedgerWalletAdapter(),
        new SolletExtensionWalletAdapter(),
        new SolletWalletAdapter(),
    ],
    [solNetwork]
  );

  let advancedState = false;
  if(localStorage.getItem("advancedState")){
    if(localStorage.getItem("advancedState")==="true" || localStorage.getItem("advancedState")===true){
      advancedState = true;
    }

  }
  //console.log(advancedState);

  useEffect(() => {
    /*const f = async () => {
        const c = await Client.init({
            logger: 'debug',
            relayUrl: 'wss://relay.walletconnect.com',
            projectId: '6b081d0019ce84b92514e7033cef30e1', // register at WalletConnect and create one for yourself - https://cloud.walletconnect.com/
            // you need to have a valid ID or the app will not start
            metadata: {
                name: "Scribe App",
                description: "Web3 XP and Leveling Platform",
                url: "https://goscribe.io/",
                icons: ["https://avatars.githubusercontent.com/u/37784886"],
            },
        });

        setClient(c);
    }

    if (client === undefined) {
        f();
    }*/
}, [client]);


  const user = JSON.parse(localStorage.getItem('user'));
  let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url; 
  if(serverURL.includes("localhost")){
    serverURL = "http://" + serverURL + ":8080";
  } else {
    serverURL = "https://" + serverURL;
  }
  
  Axios.defaults.baseURL = serverURL; //process.env.SERVER_URL ? process.env.SERVER_URL : Settings[process.env.NODE_ENV].server_url;

  //console.log("Axios.defaults.baseURL " + Axios.defaults.baseURL);

  if (user?.token){

    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;

  }

  


  // render the routes
  return(
    /*<Elements stripe={ StripePromise }>*/
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <WalletModalProvider>
    
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                { routes.map(route => {

                  return (
                    <Route 
                      key={ route.path } 
                      path={ route.path }
                      element={ 
                        
                        route.permission ? 
                          <PrivateRoute permission={ route.permission }>
                            <>
                            {/*
                              !session && (
                              <div className="box">
                                  <h3>Select chain:</h3>
                                  {chains.map((c, idx) => {
                                      return (<div key={`chain-${idx}`}>{c} <button disabled={!client} onClick={async () => await handleConnect(c)}>connect</button></div>);
                                  })}
                              </div>
                              )
                                */}
                            <View advancedState={advancedState} display={ route.view } layout={ route.layout } title={ route.title }/>
                            
                            </>

                          </PrivateRoute> :
                          <View advancedState={advancedState} display={ route.view } layout={ route.layout } title={ route.title  }/>

                      }
                    />
                  )
                })}

                { /* 404 */}
                <Route path='*' element={ <View display={ NotFound } layout='home' title='404 Not Found' /> }/>

              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>



    /*</Elements>*/
  );
}
