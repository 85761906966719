/***
*
*   NFT NAV
*   NFT sub nav that renders different options based
*   on user permissions.
*
**********/

import React from 'react';
import { SubNav } from 'components/lib';

export function NFTHolderNav(props){
  //console.log(props);
  let navItems = [

    { link: `/home`, label: '[Back]' },
    { link: `/nft/holder/${props.nft_id}`, label: 'NFT' },
    { link: `/nft/events/${props.nft_id}`, label: 'Events' },
    //{ link: '/account/2fa', label : '2FA' },
    //{ link: '/account/billing', label: 'Billing', permission: 'owner' },
    //{ link: '/account/apikeys/', label: 'API Keys', permission: 'developer' },
    //{ link: '/account/users', label: 'Users', permission: 'admin' }

  ];

  

  if(props.nft_collection && props.nft_collection.address){
    let collection_address = props.nft_collection.address;
    let collection_name = props.nft_collection.name;
    switch(collection_address){
      case "0x339a02876183cdd9f759b250ac6561ec63359e01":
        {
          navItems.push(
            { link: `/nft/config/${props.nft_id}`, label: 'Config' },
          )
          break;
        }
        
      default:
        break;

    }
  }
  
  return(
    <SubNav dark items={navItems} center={props.center} />
  );
}