/***
*
*   NFT
*   View NFT as a non-holder
*
**********/

import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
//import useWindowSize from 'react-use/lib/useWindowSize';

import { useLocation } from 'react-router-dom'
import { Image, Card, ProgressBar, Grid, AuthContext, ViewContext, 
  NFTViewNav, useNavigate, Animate, Link, Message, Button, Feedback, Row, Table, useAPI } from 'components/lib';

import Style from '../nft.tailwind.js';
import Axios from 'axios';


export function NFTViewHome(props){

  //console.log(props);
  
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();
  //const user = useAPI('/api/user');
  const creditInscribes = useAPI('/api/var/credit_inscribes');
  const location = useLocation();
  const authContext = useContext(AuthContext);


  let pathSplit = location.pathname.split("/");
  const nft_id = pathSplit[pathSplit.length-1];

  // fetch
  //const nftData = useAPI('/api/nft/' + nft_id);
  const [currentUser, setCurrentUser] = useState(null);
  const [nftData, setNFTData] = useState(null);
  const [unAssignedXP, setUnAssignedXP] = useState(0);
  
  const [hideInscribe, setHideInscribe] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [nftSignatures, setNFTSignatures] = useState(null);
  const [advanced, setAdvanced] = useState(false);

  localStorage.setItem('view_nft_id', nft_id);

  const getUser = useCallback(async () => {

    
    //console.log(authContext.user);
    const res = await Axios.get(`/api/user`);
    setCurrentUser(res.data);
    //console.log(res.data);

  },[authContext]);

  useEffect(() => {
    //console.log(viewContext);
    setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);

  useEffect(() => { 
    if(authContext) {
      //console.log(authContext);
      if(authContext.user){
        getUser();
      }
    }
  },[authContext]);

  const getDetails = useCallback(async () => {

    setHideInscribe(false);
    setDetailsLoading(true);
    //console.log("Getting Details");
    const res = await Axios.get(`/api/nft/${nft_id}`);
    //res.data.data.length ? setData(res.data.data[0]) : navigate('/404');
    //console.log(res);
    //console.log(res.data);
    //console.log(res.data.data);
    if(!res.data.success){
      console.log("Unauthorized");
      navigate('/home');
    } else {
      
      let unAssignedXP = (res.data.data.nftData.xp_raw - res.data.data.nftData.xp).toLocaleString();
      setUnAssignedXP(unAssignedXP);

      let tmpNFTData = res.data.data;
      let nftAssetData = tmpNFTData.nftAssetData;
      let fixedTraits = [];
      if(nftAssetData.traits){
        nftAssetData.traits.forEach(theTrait => {
          let newTrait = {
            trait_type: theTrait.trait_type
          }
          if(theTrait.display_type === 'number'){
            newTrait.value = theTrait.value.toLocaleString();
          } else {
            newTrait.value = isNaN(theTrait.value) ? theTrait.value : Number(theTrait.value).toLocaleString();
          }
          fixedTraits.push(newTrait);
        });
      }
      fixedTraits.sort(
        function(a, b) {          
 
          return a.trait_type > b.trait_type ? 1 : -1;
       }
      );

      nftAssetData.fixedTraits = fixedTraits;
      console.log(tmpNFTData);
      setNFTData(tmpNFTData);

      setDetailsLoading(false);      
     

    }

  }, [nft_id, navigate]);

  useEffect(() => {
    //console.log("Initial load");
    const id = nft_id;
    if (id) getDetails(id);
  }, [getDetails, nft_id])

  const nftSignComplete = async function(trimmedDataURL, signer){
    console.log("nftSignComplete");
    //console.log(trimmedDataURL);
    let tmpSignatures = nftSignatures;
    if(!nftSignatures){
      tmpSignatures = [];
    }
    tmpSignatures.push({
      image: trimmedDataURL,
      signer: signer
    });
    setNFTSignatures(tmpSignatures);
  }

  const nftSign = async function(){

    viewContext.modal.show({

      title: 'Autograph Collectible?',
      sigPad : true,
      sigPadComplete : nftSignComplete,
      signer: {
        twitter_id : '22164071',
        twitter_username: 'the_davey',
        twitter_name: 'DavΞy'
      },
      //url: '/api/nft/inscribe_collectible',
      
    }, (res, data) => {
      console.log("Done");
      console.log(res, data);
      //viewContext.notification.show(res.data.message, 'success', true);
      //getDetails();
    });

  }

  function truncateString(theString, truncLength = 50) {
    let retString = theString;
    if (theString.length > truncLength) {
      let strLen = theString.length;
      let string1 = theString.substring(0, truncLength);
      retString = string1 + "...";
    }
    return retString;
  }
  
  return (
    
    <Fragment>
      <Animate>
      {/*
      <Message color='transparent' title='Stats' 
        text={'Stats this NFT is collecting will be listed below'}
      />
      */}
      <NFTViewNav center={!advanced} dark nft_id={nft_id} />
      <Row dark>     
        
        <Grid cols='3'>
          <Card darker shadow rounded
            //badge={nftData.openSeaData.status_name } badgeText={nftData.token_name }
            loading={ detailsLoading } 
            key="nftdetails1"
            className={ Style.nftholder }
            restrictWidth
            sideBadges = {nftData ? nftData.nftData.xp_buckets : []}
            >
            {!detailsLoading && nftData && 
            <Fragment>
              <div className={ Style.collection }>
                {nftData.nftData.collection.name }
                
              </div>

              <div className={ Style.nftname }>
                {nftData.nftData.name }
              </div>
              <div id="divTokenID" className={ Style.nftname }>
                Token #{ truncateString(nftData.nftData.token_id, 25) }
              </div>

              <div className={ Style.nftname }>
                { nftData.nftData.title !== "" ? "'" + nftData.nftData.title + "'" : <>&nbsp;</>}
              </div>

              <Image
                className={ Style.nftImage }
                nftImage = {true}
                border = {true}
                source={nftData.nftData.image_url}
                alt={nftData.nftData.token_name }
              />

              <ProgressBar 
                progress={(nftData.nftData.xpStats.progress * 100) + "%"}
                label={nftData.nftData.unlocked === 1 ? `Level: ${nftData.nftData.xpStats.level}  (${nftData.nftData.xp.toLocaleString()} of ${nftData.nftData.xpStats.nextLevelXP.toLocaleString()})` : 'Activate to Level Up'}
              />

              {/*
              <ProgressBar 
                raw
                small
                progress={ 
                  unAssignedXP !== '0' ?
                  nftData.nftData.progress_raw + '%'
                  :
                  '0'
                } 
                
            

                label={
                  nftData.nftData.unlocked === 1 ? 
                    unAssignedXP === '0' ?
                    '(No Uninscribed XP)'
                    :
                    `Uninscribed XP: ${unAssignedXP}   Level: ${nftData.nftData.xpStatsRaw.level}`
                  : 
                  'Activate to Level Up'
                  }


                //label={nft.unlocked === 1 ? `Uninscribed XP: ${unAssignedXP} ${unAssignedXP > 0 ? `Level ${nft.xpRawStats.level}` : ''}` : 'Activate to Level Up'}
              />
                */}

              
            </Fragment>
            }
          </Card>
          <Card dark shadow rounded
            //badge={nftData.openSeaData.status_name } badgeText={nftData.token_name }
            smallHeader
            loading={ detailsLoading } 
            key="nftdata2"
            className={ Style.nftholder }
            title='Official Metadata'
            >
            {!detailsLoading && nftData && nftData.nftAssetData.traits && 
            <Fragment>
            <Table
              className='restrict-width'
              cellStyle='cell_small'
              headerStyle='header_small'
              data={ nftData.nftAssetData.fixedTraits }
              loading={ detailsLoading }
              show={['trait_type', 'value']}
              //actions={{ edit: editUser, delete: deleteUser, email: true }}
            />
            </Fragment>
          }

          </Card>
          <Card dark shadow rounded
            //badge={nftData.openSeaData.status_name } badgeText={nftData.token_name }
            loading={ detailsLoading } 
            key="nftdata3"
            className={ Style.nftholder }
            restrictWidth
            >
            {!detailsLoading && nftData && 
            <Fragment>
              <h2>Info</h2>
              <div className={ Style.collection }>
                <p><strong>wallet_address: </strong><br/>{nftData.nftData.wallet_address}</p>
                <p><strong>asset_contract: </strong><br/>{nftData.nftData.asset_contract}</p>
                <p><strong>token_id: </strong><br/>{nftData.nftData.token_id}</p><br/>
                {nftData.nftAssetData && nftData.nftAssetData.permalink && <Link url={`${nftData.nftAssetData.permalink}`} target='_blank' text='OpenSea Listing' />}
                <br/>
              </div>
              {
                currentUser && !nftSignatures && 1===1 && 
                <>
                  <br/>
                  <Button 
                    className={ Style.inscribeAllBtn }
                    action={ e => nftSign(nft_id) }
                    text={ currentUser ? `Autograph This NFT` : `Log In`}
                  />
                  
                </>
              } 
             
              {nftSignatures && nftSignatures.length > 0 &&
                nftSignatures.map((theSignature, index) => {
                  return (
                    <div id={`div-sig-image${index}`} key={`div-sig-image${index}`} >
                      <div className={ Style.collection }>Confirmed Signed By: <Link url={`https://twitter.com/${theSignature.signer.twitter_username}`} target='_blank' text={`${theSignature.signer.twitter_name}`} /></div>
                      <Image
                        id={`sig-image${index}`} 
                        key={`sig-image${index}`}
                        className={ Style.signatureImage }
                        nftImage = {true}
                        border = {true}
                        source={ theSignature.image}
                        alt={ 'Autographed!' }
                      /> 

                       {/*<img id={`sig-image${index}`} key={`sig-image${index}`} src={theSignature} alt="Your signature will go here!"/>*/}
                    </div>
                  )
                })
              }
            </Fragment>
          }

          </Card>

          
        </Grid>
            
          
          {!detailsLoading && nftData && nftData.nftData.application_buckets.length === 0 && 
            <Message 
              color='transparent' 
              title='Stats' 
              text={'Stats this NFT is collecting will be listed below'}
            />
          }

          
            <Grid cols='6'>
            {!detailsLoading && nftData && 
              nftData.nftData.application_buckets.map((stat, index) => {
                return (
                <Card 
                  dark                  
                  key={stat.id}
                  shadow rounded smallHeader
                  title={stat.application_name}
                  className={ Style.nftStatCardCenter }

                >

                  <Image
                    className={ Style.applicationImage }
                    nftImage = {true}
                    border = {true}
                    source={ stat.logo_square_url}
                    alt={ stat.application_name }
                  />
                  <div className={ Style.nftStatCenter }>
                    <strong>XP:</strong> {stat.event_total.toLocaleString() }<br/>
                    {/*}
                    {stat.uninscribedValue > 0 && 
                      <>
                      Uninscribed {stat.stat_name.toUpperCase() }: {stat.uninscribedValue.toLocaleString() }
                      </>
                    }*/}
                    
                  </div>

                  {/*
                  {stat.uninscribedValue && stat.uninscribedValue > 0 ?
                    !hideInscribe && 
                    <div className={ Style.nftStatCenter }>
                      <strong>Cost to Inscribe: { nftData.nftData.level >= 10 ? `(${!creditInscribes.loading && creditInscribes.data === 1 ? stat.inscribeCreditCost.toLocaleString() + ' Credits)': 'Free)'} ` : `Free`}</strong>
                      </div>
                    :
                    <div className={ Style.nftStatCenter }>
                      <strong>
                      --Fully Inscribed--<br/>
                      </strong>
                    </div>
                  }
                */}
                  
                </Card>
                )
              })
            }
          </Grid>
          </Row>

        <Feedback />
      </Animate>
    </Fragment>
  );
}
