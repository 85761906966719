/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, Fragment} from 'react';

import { Card, ViewContext, Stat, ProgressBar, Chart,  AuthContext, Image, Button, Row, Link, SocialShare, SignIn, DashboardNav,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, Form } from 'components/lib';

//import { useWeb3React } from "@web3-react/core";

import Axios from 'axios';
import Style from './community.tailwind.js';

export function NewCommunity(props){

  
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();
  const [showNFTFields, setShowNFTFields] = useState(false);
  const [formData, setFormData] = useState(null);
  const [currentFormData, setCurrentFormData] = useState([]);

  const authContext = useContext(AuthContext);
  const user = useAPI('/api/user', authContext);

  const [advanced, setAdvanced] = useState(false);
  

  useEffect(() => { 
    //console.log("Loading community page");
    if(localStorage.getItem('new_community')){
      //console.log("Removing new_community cookie");
      localStorage.removeItem('new_community');
    } else {
      
      if(!authContext.user){
        //console.log("Setting new_community cookie")
        localStorage.setItem('new_community', true);
      }
    }

  },[]);

  
  
  useEffect(() => { 
    if(!authContext.user){
      console.log("Signed out");
      localStorage.setItem("new_community", true);
    } else {
      console.log("Signed in");
      localStorage.removeItem("new_community");
    }

  },[authContext]);



  let theFormData = {
    community_type: {
      type: 'checkbox',
      label: 'Community Type',
      required: true,
      min: 1,
      default: [],
      options: ['NFT','Game','Guild','Personality/Brand','Interest Group','Other'],
      errorMessage: 'Please select at least one community type' 
    },
    community_name: {
      label: "Community Name",
      type: "text",
      //value: `${showNFTFields}`,
      required: true,
    },
    community_email: {
      label: "Community Contact Email",
      type: "email",
      required: false,
    },
    community_twitter: {
      label: "Community Twitter Handle (ie @username)",
      type: "text",
      //value: user? "@" + user.data.name : "",
      required: true,
    },
    description: {
      label: "Description",
      type: "textarea",
      required: true,
    },
    discord_url: {
      label: "Discord URL",
      type: "text",
      required: false,
    },
    discord_server_id: {
      label: "Discord Server ID (from the Widget area of your Discord Server Settings)",
      type: "text",
      required: false,
    },
    /*image_url: {
      label: "Logo URL (if available)",
      type: "text",
      required: false,
    },*/
    website: {
      label: "Main Website (if available)",
      type: "text",
      required: false,
    },
    
    
    
  }
  //const [projectList, setProjectsList] = useState(null);
  
  /*useEffect(() => { 
    async function getProjectList() {
      if(user.data) {
        //console.log(user.data);
        const res = await Axios.get('/api/projects/list');
        //console.log(res.data);
        setProjectsList(res.data.data.projects);
      }
    }

    //getProjectList();

  },[user]);*/

  useEffect(() => {
    return;
    //console.log(user);
    if(!user.data){
      return;
    }
    
    //console.log("Initializing form data");
    //theFormData.community_name.value = `${showNFTFields}`;
    //theFormData.community_twitter.value = "@" + user.data.name;
    theFormData.community_type.value = currentFormData['community_type'];
    theFormData.community_name.value = currentFormData['community_name'];
    theFormData.community_twitter.value = currentFormData['community_twitter'];
    theFormData.description.value = currentFormData['description'];
    theFormData.discord_url.value = currentFormData['discord_url'];
    theFormData.website.value = currentFormData['website'];
    theFormData.community_email.value = currentFormData['community_email'];

    if(showNFTFields){
      
      /*case "community_name": existingFormData['community_name'] = e.value; break;
      case "community_twitter": existingFormData['community_twitter'] = e.value; break;
      case "description": existingFormData['description'] = e.value; break;
      case "discord_url": existingFormData['discord_url'] = e.value; break;
      case "website": existingFormData['website'] = e.value; break;
      case "community_email": existingFormData['community_email'] = e.value; break;*/
      

      theFormData.contract_address= {
        label: "Primary Contract Address",
        type: "text",
        //value: `${showNFTFields}`,
        //required: true,
      };

      theFormData.chain= {
        type: 'checkbox',
        label: 'Blockchain',
        required: true,
        min: 1,
        default: ['ETH'],
        options: ['ETH','AVAX','SOL','POLY','CRO','KDA','Other'],
        //errorMessage: 'Please select at least one community type' 
      };
    } else {
      delete theFormData.chain;
      delete theFormData.contract_address;
    }
    
    setFormData(theFormData);
  }, [user, showNFTFields]);


  async function viewProject(project) {
    console.log(project);
    let navPath = `/c/${project.id}`;
    //console.log(navPath);
    navigate(navPath);
   
  };


  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);

  const beTheChange = async(e, stateName, showModal) => {
    //console.log("beTheChange");
    console.log(e);
    return;
    if(e.input === 'community_type'){

      let communityTypes = e.value;
      
      if(communityTypes.includes("NFT")){
        setShowNFTFields(true);
      } else {
        setShowNFTFields(false);
      }
      
    }

    let existingFormData = currentFormData;
    switch(e.input){
      case "community_type": existingFormData['community_type'] = e.value; break;
      case "community_name": existingFormData['community_name'] = e.value; break;
      case "community_twitter": existingFormData['community_twitter'] = e.value; break;
      case "description": existingFormData['description'] = e.value; break;
      case "discord_url": existingFormData['discord_url'] = e.value; break;
      case "website": existingFormData['website'] = e.value; break;
      case "community_email": existingFormData['community_email'] = e.value; break;
      default:
    }
    setCurrentFormData(existingFormData);
    //console.log(existingFormData);


  }


  if(!authContext.user){
    return (
      <>
      <Card dark700 center restrictWidthWide>
        <SignIn title="Signin Required" text="You must sign in to create a new community." localStorage="new_community" />
      </Card>
        
      </>
    );
  }

  return (
<Fragment>
    {user && user.data && 
      <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
    }

    <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      
      <Message
        className={Style.smallMessage}
        messageID="Message_Communities_New"
        title="Register your Community"
        text="Begin tracking engagement metrics and rewarding Social XP!"
        //buttonText="My Collectibles"
        //buttonLink="/collectibles"
        type="info"
        noIcon
      />
      <Card dark restrictWidthWide>
        
        <Form 
          data={{
            community_type: {
              type: 'checkbox',
              label: 'Community Type',
              required: true,
              min: 1,
              default: [],
              options: ['NFT','Game','Guild','Personality/Brand','Interest Group','Other'],
              errorMessage: 'Please select at least one community type' 
            },
            community_name: {
              label: "Community Name",
              type: "text",
              //value: `${showNFTFields}`,
              required: true,
            },
            community_email: {
              label: "Community Contact Email",
              type: "email",
              required: false,
            },
            community_twitter: {
              label: "Community Twitter Handle (ie @username)",
              type: "text",
              //value: user? "@" + user.data.name : "",
              required: true,
            },
            description: {
              label: "Description",
              type: "textarea",
              required: true,
            },
            discord_url: {
              label: "Discord URL",
              type: "text",
              required: false,
            },
            discord_server_id: {
              label: "Discord Server ID (from the Widget area of your Discord Server Settings)",
              type: "text",
              required: false,
            },
            /*image_url: {
              label: "Logo URL (if available)",
              type: "text",
              required: false,
            },*/
            website: {
              label: "Main Website (if available)",
              type: "text",
              required: false,
            },
          }
            
          }
          method='PUT'
          url='/api/community/intake'
          //updateOnChange
          className={ Style.form }
          buttonText='Submit'
          /*onChange={ e => {
            beTheChange(e)
          }}*/
          callback={ (res, data) => {
            console.log(res);
            //setShowForm(false);
            //viewContext.notification.show('Thank you, your feedback is most appreciated.', 'success', true, 'toast', 'heart');
            if(res.data?.message){
              viewContext.notification.show(res.data.message, res.data.success !== undefined ? res.data.success ? 'success' : 'error' : 'success', true);

              if(res.data.success){
                let slug = res.data.slug;
                setTimeout(() => { navigate('/c/' + slug)}, 3000);
                
              }
            }

          }}
        />
        
      </Card>
      </div>
      </Fragment>      



  );
}
