/***
*
*   CARD
*   Universal container for grouping UI components together
*
*   PROPS
*   title: title string (optional)
*   loading: boolean to toggle the loading animation (optional)
*   shadow: apply a box shadow
*   center: center align the card
*   noPadding: remove the padding
*   restrictWidth: restrict the width of the card on large screens
*
**********/

import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { Loader, ClassHelper, Badge, Icon } from 'components/lib';
import {isMobile} from 'react-device-detect';
import Style from './card.tailwind.js';


export function Card(props){

  const [sideBadges, setSideBadges] = useState([]);
  const cardStyle = ClassHelper(Style, {

    dark: props.dark,
    dark700: props.dark700,
    darker: props.darker,
    shadow: props.shadow,
    center: props.center, 
    noPadding: props.noPadding,
    tightPadding: props.tight,
    loading: props.loading,
    className: props.className,
    last: props.last,
    restrictWidth: props.restrictWidth,
    restrictWidthWide: props.restrictWidthWide,
    restrictWidthWider: props.restrictWidthWider,
    interactive: props.interactive,
    smallBorder: props.smallBorder

  });

  let headerStyle = props.smallHeader ? Style.smallHeader : props.blueHeader ? Style.blueHeader : Style.header;
  if (props.dark){
    headerStyle = Style.lightHeader;
  }


  useEffect(() => {
    if(props.sideBadges){
      let actualSideBadges = props.sideBadges.filter((function (x) {
        return (x.level_inscribed > 0 ) || (x.level_uninscribed > 0) || (x.level_raw > 0);
      }));
      setSideBadges(actualSideBadges);
    }

  },[props.sideBadges]);
  

  return (
    <section 
      className={ cardStyle }
      onClick={ e => {
        
        if(props.interactive){
          e.preventDefault();
          e.stopPropagation();
        }
        
  
        props.action && props.action(props.params);
        props.goto && History.push(props.goto);
        if (props.url) window.location = props.url;
  
      }}
    >

      { props.title &&
        <header className={ (props.center ? 'center ' : '') + headerStyle }>
          <h1 className={ Style.title }>{ props.title }</h1>
          { props.subtitle &&
            <p>{ props.subtitle }</p>
          }
        </header>
      }

    

      {props.badge && props.badgeText !== '' && 
        <Badge id={props.id + "badge1"} key={props.id + "badge1"} text={ props.badgeText } color={ props.badgeColor } className={ Style.badge }/>
      }

      {props.badge2 && props.badge2Text !== '' && 
        <Badge id={props.id + "badge2"} key={props.id + "badge2"} text={ props.badge2Text } color={ props.badge2Color } className={ Style.badge2 }/>
      }

      {props.ulBadge && 
        <Badge id={props.id +"ul_badge"} key={props.id +"ul_badge"} text={ props.ulBadge } color={ props.badgeULColor } className={ Style.badgeUL }/>
      }

      {props.topBadge && 
        <Badge id={props.id +"top_badge"} key={props.id +"top_badge"} text={ props.topBadge } color={ props.badgeTopColor } className={ Style.badgeTop }/>
      }

      {props.brandIcon && props.brandIcon !== '' && props.brandIcon !== null && props.brandIcon !== undefined && 
        <FontAwesomeIcon  id={props.id +"fa_badge"} key={props.id +"fa_badge"} icon={['fab', props.brandIcon]} size='lg' className={ Style.platformIcon } />
      }

      {isMobile && 1===3 ? 
      <></>
      :
        <>
        {sideBadges && sideBadges.length > 0 &&
          <>
          <Badge id="sidebadge1a" key="sidebadge1a" text={ sideBadges[0].application_type } color={ "orange" } className={ Style.badgeSide1 }/>
          {sideBadges[0].level_inscribed !== undefined  && <Badge id="sidebadge1b1" key="sidebadge1b1" text={ "LVL " + sideBadges[0].level_inscribed } color={ "whiteborder" } className={ Style.badgeSide1Text }/>}
          {sideBadges[0].level_raw !== undefined && <Badge id="sidebadge1b2" key="sidebadge1b2" text={ "LVL " + sideBadges[0].level_raw} color={ "whiteborder" } className={ Style.badgeSide1Text }/>}
          </>
        }

        {sideBadges && sideBadges.length > 1 &&
          <>
          <Badge id="sidebadge2a" key="sidebadge2a" text={ sideBadges[1].application_type } color={ "orange" } className={ Style.badgeSide2 }/>
          {sideBadges[1].level_inscribed !== undefined && <Badge id="sidebadge2b1" key="sidebadge2b1" text={ "LVL " + sideBadges[1].level_inscribed  } color={ "whiteborder" } className={ Style.badgeSide2Text }/>}
          {sideBadges[1].level_raw !== undefined && <Badge id="sidebadge2b2" key="sidebadge2b2" text={ "LVL " + sideBadges[1].level_raw } color={ "whiteborder" } className={ Style.badgeSide2Text }/>}
          </>
        }

        {sideBadges && sideBadges.length > 2 &&
          <>
          <Badge id="sidebadge3a" key="sidebadge3a" text={ sideBadges[2].application_type } color={ "orange" } className={ Style.badgeSide3 }/>
          {sideBadges[2].level_inscribed !== undefined && <Badge id="sidebadge3b1" key="sidebadge3b1" text={ "LVL " + sideBadges[2].level_inscribed } color={ "whiteborder" } className={ Style.badgeSide3Text }/>}
          {sideBadges[2].level_raw !== undefined && <Badge id="sidebadge3b2" key="sidebadge3b2" text={ "LVL " + sideBadges[2].level_raw} color={ "whiteborder" } className={ Style.badgeSide3Text }/>}
          </>
        }

        {sideBadges && sideBadges.length > 3 && 
          <>
          <Badge id="sidebadge4a" key="sidebadge4a" text={ sideBadges[3].application_type } color={ "orange" } className={ Style.badgeSide4 }/>
          {sideBadges[3].level_inscribed !== undefined && <Badge id="sidebadge4b1" key="sidebadge4b1" text={ "LVL " + sideBadges[3].level_inscribed } color={ "whiteborder" } className={ Style.badgeSide4Text }/>}
          {sideBadges[3].level_raw !== undefined && <Badge id="sidebadge4b2" key="sidebadge4b2" text={ "LVL " + sideBadges[3].level_raw} color={ "whiteborder" } className={ Style.badgeSide4Text }/>}
          </>
        }
        </>
      }
      


      
      

      { props.loading ?
        <Loader /> :
        props.children
      }
    </section>
  );
}
