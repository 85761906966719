/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
//import useWindowSize from 'react-use/lib/useWindowSize';
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
import Confetti from "react-confetti";
import { useLocation } from "react-router-dom";
import {
  ViewContext,
  AuthContext,
  Image,
  Card,
  ProgressBar,
  Grid,
  SocialShare,
  NFTHolderNav,
  Button,
  useNavigate,
  Animate,
  useAPI,
  Row,
  Link,
  Message,
  Feedback,
  Table,
} from "components/lib";

import Style from "../nft.tailwind.js";
import { useWeb3React } from "@web3-react/core";
import Axios from "axios";

//Solana stuff
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
//import { Keypair, Transaction, PublicKey } from "@solana/web3.js";
// New import
import {
  findTransactionSignature,
  FindTransactionSignatureError,
  findReference,
  FindReferenceError,
} from "@solana/pay";
import {
  Cluster,
  clusterApiUrl,
  Connection,
  PublicKey,
  Keypair,
} from "@solana/web3.js";
import { encodeURL, createQR } from "@solana/pay";
import BigNumber from "bignumber.js";

export function NFTHolderHome(props) {
  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const [width, height] = useWindowSize();
  //const onlyWidth = useWindowWidth()
  //const onlyHeight = useWindowHeight()
  const user = useAPI("/api/user", authContext);
  //const currentUserCreditInfo = useAPI('/api/shop/credits');
  const userSite = useAPI("/api/site/holder_home");
  const showUpgradesVar = useAPI("/api/var/show_upgrades");
  const showPromotionsVar = useAPI("/api/var/show_promotions");
  const superPassUpgradesVar = useAPI("/api/var/superpass_upgrades");
  const creditDiscount = useAPI("/api/var/credit_discount");
  const creditInscribes = useAPI("/api/var/credit_inscribes");

  let pathSplit = location.pathname.split("/");
  const nft_id = pathSplit[pathSplit.length - 1];

  // fetch
  //const nftData = useAPI('/api/nft/' + nft_id);
  const [nftData, setNFTData] = useState(null);
  const [unAssignedXP, setUnAssignedXP] = useState(0);
  const { active, account, chainId } = useWeb3React();
  const [existingAccount, setAccount] = useState(null);
  const [confettiOn, setConfettiOn] = useState(false);
  const [doConfetti, setDoConfetti] = useState(false);
  const [hideInscribe, setHideInscribe] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [message, setMessage] = useState(null);
  const [payLink, setPayLink] = useState(null);
  const [payReference, setPayReference] = useState(null);
  const [NFTProjectData, setNFTProjectData] = useState(null);
  const [projectTokenMetaData, setProjectTokenMetadata] = useState(null);
  const [canInscribe, setCanInscribe] = useState(false);
  const [showUpgrades, setShowUpgrades] = useState(false);
  const [showSuperPassUpgrades, setShowSuperPassUpgrades] = useState(false);
  const [upgradeOptions, setUpgradeOptions] = useState(null);
  const [userFlags, setUserFlags] = useState([]);
  const [currentUserCreditInfo, setCurrentUserCreditInfo] = useState(null);
  const [promotions, setPromotions] = useState(false);
  const [showPromotions, setShowPromotions] = useState(false);
  const [advanced, setAdvanced] = useState(false);

  // We get the public key of the connected Solana wallet, if there is one
  //const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  //console.log("Solana wallet: " + publicKey);

  const [connection, setConnection] = useState(null);

  const [battlefieldLink, setBattlefieldLink] = useState(null);
  //const [currentUserCreditInfo, setCurrentUserCreditInfo] = useState(null);
  const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

  useEffect(() => {
    //console.log(viewContext);
    setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);

  useEffect(() => {
    if (currentUserCreditInfo?.creditBalance) {
      /*if (nftData && nftData.totalStatUninscribed) {
        setCanInscribe(false);
      }*/
    }
  }, [currentUserCreditInfo]);

  useEffect(() => {
    if (user.data) {
      //console.log(user);
      let userflags = user.data.flags ? user.data.flags.split(",") : [];
      //console.log(userflags);
      setUserFlags(userflags);
    }
  }, [user]);

  useEffect(() => {
    if (userSite?.loading) {
    } else {
      //console.log(userSite);
      if (userSite.data) {
        if (userSite.data.message) {
          //console.log(userSite.data.message);
          viewContext.notification.show(userSite.data.message, "success", false);
        }
      }
    }
  }, [userSite]);

  useEffect(() => {
    if (showUpgradesVar?.loading) {
      //console.log(showUpgrades);
    } else {
      //console.log("Upgrades");
      //console.log(showUpgradesVar);
      if (showUpgradesVar.data === 1) {
        setShowUpgrades(true);
      }
    }
  }, [showUpgradesVar]);

  useEffect(() => {
    if (showPromotionsVar?.loading) {
      //console.log(showUpgrades);
    } else {
      //console.log("Upgrades");
      //console.log(showUpgradesVar);
      if (showPromotionsVar.data === 1) {
        setShowPromotions(true);
      }
    }
  }, [showPromotionsVar]);

  useEffect(() => {
    if (superPassUpgradesVar?.loading) {
      //console.log(showUpgrades);
    } else {
      //console.log(showUpgradesVar);
      if (superPassUpgradesVar.data === 1) {
        setShowSuperPassUpgrades(true);
      }
    }
  }, [superPassUpgradesVar]);

  const getDetails = useCallback(async () => {
    setHideInscribe(false);
    setDetailsLoading(true);
    //console.log("Getting Details");
    const res = await Axios.get(`/api/nft/${nft_id}?filterEvents=true`);

    //console.log(res.data.data);
    if (!res.data.success) {
      console.log("Unauthorized");
      navigate("/home");
    } else {
      /*let unAssignedXP = (
        res.data.data.nftData.xp_raw - res.data.data.nftData.xp
      ).toLocaleString();
      setUnAssignedXP(unAssignedXP);
      */

      let tmpNFTData = res.data.data;
      let nftAssetData = tmpNFTData.nftAssetData;
      let fixedTraits = [];
      

      if (nftAssetData.traits) {
        nftAssetData.traits.forEach((theTrait) => {
          let newTrait = {
            trait_type: theTrait.trait_type,
          };
          if (theTrait.display_type === "number") {
            newTrait.value = theTrait.value.toLocaleString();
          } else {
            newTrait.value = isNaN(theTrait.value)
              ? theTrait.value
              : Number(theTrait.value).toLocaleString();
          }
          fixedTraits.push(newTrait);
        });
      }

     


      fixedTraits.sort(function (a, b) {
        return a.trait_type > b.trait_type ? 1 : -1;
      });

      nftAssetData.fixedTraits = fixedTraits;

      //let totalStatUninscribed = 0;
      //let totalStatCost = 0;
      
      /*if (tmpNFTData.statsDataRaw) {
        tmpNFTData.statsDataRaw.forEach((stat, index) => {
          //console.log(stat);
          let thisStatUninscribedValue = stat.uninscribedValue;
          let thisStatInscribeCost = stat.inscribeCost;
          totalStatCost += thisStatInscribeCost;
          totalStatUninscribed += thisStatUninscribedValue;
        });
      }*/

      //console.log(totalStatCost);
      //console.log(totalStatUninscribed);
      //tmpNFTData.totalStatCost = totalStatCost;
      //tmpNFTData.totalStatUninscribed = totalStatUninscribed;

      //Credits used is the difference between
      //Credit p
      console.log(tmpNFTData);
      setNFTData(tmpNFTData);
      
      if (tmpNFTData.nftData.collection.address === "0x339a02876183cdd9f759b250ac6561ec63359e01"){
        let bfSlug = tmpNFTData.nftData.slug;
        let nftName = tmpNFTData.nftData.nickname;
        if(bfSlug == null){
          if(nftName == null){
            nftName = tmpNFTData.nftData.name;
          }
          bfSlug = slugify(nftName);
        }
        setBattlefieldLink("https://app.nftscribe.io/game/" + bfSlug);
      }
          
      

      if (res.data.data.projectTokenData)
        setNFTProjectData(res.data.data.projectTokenData);
      if (res.data.data.projectTokenMetadata)
        setProjectTokenMetadata(res.data.data.projectTokenMetadata);

      if (
        tmpNFTData.projectTokenData &&
        tmpNFTData.projectTokenData.project.upgradable === 1
      ) {
        const resUpgrade = await Axios.post("/api/project/upgrade/options", {
          nft_id: nft_id,
        });

        //console.log(resUpgrade.data.data.upgradeOptions);
        setUpgradeOptions(resUpgrade.data.data.upgradeOptions);
      }

      const resCredits = await Axios.get(`/api/shop/credits`);
      //console.log(resCredits.data.data);
      setCurrentUserCreditInfo(resCredits.data.data);

      const resPromotions = await Axios.get(`/api/nft/promotions/${nft_id}`);
      console.log(resPromotions.data);

      if (resPromotions.data.success && resPromotions.data) {
        if (resPromotions.data.success) {
          //console.log("has promotions");
          let promotionsData = resPromotions.data.data.promotions;
          if (promotionsData) {
            setPromotions(promotionsData);
            console.log(promotionsData);

            viewContext.notification.show(
              resPromotions.data.message,
              "success",
              true
            );
          }
        }
      } else {
        setPromotions(null);
      }

      setDetailsLoading(false);
      /*if(doConfetti){
        setConfettiOn(true);
      }*/
    }
  }, [nft_id, navigate, doConfetti]);

  useEffect(() => {
    //console.log("Initial load");
    const id = nft_id;
    if (id) getDetails(id);
  }, [getDetails, nft_id]);

  useEffect(() => {
    if (!connection && 1 == 3) {
      console.log("1. ✅ Establish connection to the network");
      let theConnection = new Connection(
        clusterApiUrl("mainnet-beta"),
        "confirmed"
      );
      //let theConnection = new Connection(clusterApiUrl('devnet'), 'confirmed');
      setConnection(theConnection);
    }

    if (active) {
      //console.log("active");
      async function changeAccount() {
        //console.log("Account changed " + account);
        setAccount(account);
      }

      if (existingAccount !== account) {
        changeAccount();
        //getNFTData();
      }
    } else {
      //console.log("Not active");
      //setNFTData(null);
      setAccount(null);
    }
  }, [existingAccount, setAccount, account, active, chainId]);

  useEffect(() => {
    //console.log(nft_id);
  }, [nft_id]);

  // Check every 0.5s if the transaction is completed
  useEffect(() => {
    if (connection && payReference && 1 == 3) {
      let signatureInfo;

      return new Promise((resolve, reject) => {
        /**
         * Retry until we find the transaction
         *
         * If a transaction with the given reference can't be found, the `findReference`
         * function will throw an error. There are a few reasons why this could be a false negative:
         *
         * - Transaction is not yet confirmed
         * - Customer is yet to approve/complete the transaction
         *
         * You can implement a polling strategy to query for the transaction periodically.
         */
        const interval = setInterval(async () => {
          console.log("Checking for transaction reference: " + payReference);
          try {
            signatureInfo = await findReference(connection, payReference, {
              finality: "confirmed",
            });
            console.log("\n 🖌  Signature found: ", signatureInfo.signature);
            clearInterval(interval);
            resolve(signatureInfo);
          } catch (error) {
            if (!(error instanceof FindReferenceError)) {
              console.error(error);
              clearInterval(interval);
              reject(error);
            }
          }
        }, 1000);
      });
    }
    /*
  const interval = setInterval(async () => {
    try {
      // Check if there is any transaction for the reference
      const signatureInfo = await findTransactionSignature(connection, reference, {})
      console.log('They paid!!!')
    } catch (e) {
      if (e instanceof FindTransactionSignatureError) {
        // No transaction found yet, ignore this error
        console.log("Not found yet");
        return;
      }
      console.error('Unknown error', e)
    }
  }, 500)
  return () => {
    clearInterval(interval)
  }
  */
  }, [payReference]);

  async function trySendTransaction() {
    if (!transaction) {
      return;
    }
    try {
      await sendTransaction(transaction, connection);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    trySendTransaction();
  }, [transaction]);

  //const reference = useMemo(() => Keypair.generate().publicKey, []);

  async function inscribeCheck(stat) {
    let totalCost = 0;

    let nft = nftData.nftData;
    console.log(stat);
    //console.log(nftData);

    if (stat === "all") {
      totalCost = nftData.totalStatUninscribed;
    } else {
      totalCost = stat.inscribeCreditCost;
    }

    let reference_event_id = stat.last_event_id;
    if (nft.level < 10) {
      //There will be a threshold event id
      if (stat.thresholdEventID) {
        //console.log("Using threshold");
        reference_event_id = stat.thresholdEventID;
      }
    }

    if (nft.level < 10) {
      viewContext.modal.show(
        {
          title: "Inscribe Collectible (NFT)?",
          form: {
            nft_id: {
              type: "hidden",
              value: nft.id,
            },
            user_id: {
              type: "hidden",
              value: nft.user_id,
            },
            stat_id: {
              type: "hidden",
              value: stat === "all" ? "all" : stat.id,
            },
            event_id: {
              type: "hidden",
              value: reference_event_id,
            },
            credits: {
              type: "hidden",
              value: totalCost,
            },
          },
          buttonText: "Inscribe",
          url: "/api/nft/inscribe_collectible",
          method: "POST",
          text: "Do you want to inscribe this Collectible (NFT)?",
          text2:
            "Inscribing a Collectible makes your experience permanent. It becomes part of the Collectible.",
          preText:
            "Note: This will inscribe up to level 10 for free. Any XP earned after reaching level 10 will cost credits to inscribe.",
        },
        () => {
          //viewContext.notification.show(res.data.message, 'success', true);
          getDetails();
        }
      );
    } else {
      /*
    nft_id: stat.nft_id,
    stat_id: stat.id, 
    event_id: stat.last_event_id
    */
      if (totalCost > currentUserCreditInfo.creditBalance) {
        viewContext.notification.show(
          "Insufficient Credits. Visit the shop to get more.",
          "error",
          false
        );
        return;
      }

      viewContext.modal.show(
        {
          title: "Inscribe Collectible (NFT)?",
          form: {
            nft_id: {
              type: "hidden",
              value: nft.id,
            },
            user_id: {
              type: "hidden",
              value: nft.user_id,
            },
            stat_id: {
              type: "hidden",
              value: stat === "all" ? "all" : stat.id,
            },
            event_id: {
              type: "hidden",
              value: reference_event_id,
            },
            credits: {
              type: "hidden",
              value: totalCost,
            },
          },
          buttonText: "Inscribe",
          url: "/api/nft/inscribe_collectible",
          method: "POST",
          text:
            "Do you want to spend " +
            totalCost.toLocaleString() +
            " Metaverse Credits to inscribe this NFT?",
          text2:
            "Inscribing a Collectible makes your experience permanent. It becomes part of the Collectible.",
          preText: `You currently have ${currentUserCreditInfo.creditBalance.toLocaleString()} Metaverse Credits.`,
        },
        () => {
          //viewContext.notification.show(res.data.message, 'success', true);
          getDetails();
        }
      );
    }
  }

  async function inscribe(stat) {
    setHideInscribe(true);

    const res = await Axios.post("/api/nft/inscribe", {
      nft_id: stat.nft_id,
      stat_id: stat.id,
      event_id: stat.last_event_id,
    });
    viewContext.notification.show(res.data.message, "success", true);
    //console.log(res.data);
    //setDoConfetti(true);
    getDetails();
    setConfettiOn(true);
    //window.location.reload(false);
  }

  async function confettiStopped() {
    //setDoConfetti(false);
    setConfettiOn(false);
    //console.log("Complete");

    //window.location.reload(true);
  }

  async function deactivateNFT(nft) {
    console.log(nft);
    //console.log(account);

    viewContext.modal.show(
      {
        title: "Deactivate NFT?",
        form: {
          id: {
            type: "hidden",
            value: nft.id,
          },
          user_id: {
            type: "hidden",
            value: nft.user_id,
          },
        },
        buttonText: "Deactivate",
        url: "/api/nft/ntrack",
        method: "POST",
        destructive: true,
        text: "Are you sure you want to deactivate this NFT?",
      },
      () => {
        //viewContext.notification.show(res.data.message, 'success', true);
        navigate("/home");
      }
    );
  }

  async function refreshNFT(nft) {
    //console.log(nft);
    //console.log(account);

    const res = await Axios.post("/api/nft/updateTokenMetadata", {
      nft_id: nft.id,
    });
    viewContext.notification.show(res.data.message, "success", true);
    setTimeout(() => {
      getDetails(nft);
    }, 2000);
  }

  async function forceMatch(nft) {
    console.log(nft);
    //console.log(account);

    viewContext.modal.show(
      {
        title: "Force Profile Match",
        form: {
          nft_id: {
            type: "hidden",
            value: nft.id,
          },
          user_id: {
            type: "hidden",
            value: nft.user_id,
          },
        },
        buttonText: "Force",
        url: "/api/nft/forceImageMatch",
        method: "POST",
        destructive: true,
        text: "Use this option only if your NFT is non-square, animated, or you are not getting XP. Are you sure you want to force this NFT to match your current profile pic?",
      },
      (res, data) => {
        console.log(res);
        console.log(data);
        //viewContext.notification.show(res.data.message, 'success', true);
        //navigate('/home')
        viewContext.notification.show("Profile matched to NFT", "success", true);
      }
    );

    /*const res = await Axios.post('/api/nft/forceImageMatch', { 
    nft_id: nft.id,
  });*/
  }

  function truncateString(theString, truncLength = 50) {
    let retString = theString;
    if (theString.length > truncLength) {
      let strLen = theString.length;
      let string1 = theString.substring(0, truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  function changeNickName(nft) {
    viewContext.modal.show(
      {
        title: "Set Nickname",
        form: {
          nft_id: {
            type: "hidden",
            value: nft.id,
          },
          nft_nickname: {
            label: "NFT Nickname",
            type: "text",
            value: nft.nickname,
            required: false,
          },
        },
        buttonText: "Set Nickname",
        url: "/api/nft/nickname",
        method: "POST",
        destructive: false,
        text: "Add a Nickname for your NFT!",
      },
      (res, data) => {
        
        if(data){
          viewContext.notification.show(
            "Nickname Changed!",
            "success",
            true,
            "toast",
            "heart"
          );
          getDetails();
        }
        
      }
    );
  }

  async function upgradeRequestComplete(nft, res) {
    console.log("upgradeRequestComplete");
    console.log(res);

    setTimeout(() => {
      getDetails(nft);
    }, 6000);
    viewContext.notification.show(res.message, "success", true);
    viewContext.modal.show(
      {
        title: "Upgrade Complete",
        buttonText: "OK",
        destructive: false,
        preText: "Changes: " + res.tokenChanges,
        text:
          res.message +
          " Metadata will refresh in a few more seconds, please wait.",
        text2: "Don't forget to update your profile pictures on Twitter, etc!",
        image: res.image,
      },
      () => {}
    ); //modal
  }

  async function viewPromotion(promotion, cost) {
    console.log(promotion);
    return window.open(`${promotion.link}`, "_blank");
  }

  async function viewImage(image_type) {
    
    let imageURL = `https://nftscribe.s3.amazonaws.com/nfts/${nftData.nftData.id}/${nftData.nftData.id}`; 
    switch(image_type){
      case "normal":
        imageURL += '.jpeg'; 
        break;
      case "thumb":
        imageURL += '_thumb.jpeg'; 
        break;
      case "square":
        imageURL += '_square.jpeg'; 
        break;
      case "round":
        imageURL += '_round.png'; 
        break;        
      default:
        imageURL += '.jpeg'; 
        break;
        
    }
    return window.open(`${imageURL}`, "_blank");
  }

  async function upgradeRequestRandom(nft, cost) {
    setDetailsLoading(true);

    viewContext.modal.show(
      {
        title: "Upgrade Item?",
        form: {
          nft_id: {
            type: "hidden",
            value: nft.id,
          },
          type: {
            type: "hidden",
            value: "random",
          },
        },
        buttonText: "Upgrade Now",
        text: `Are you sure you want to upgrade a random stat on ${nft.name} for ${cost} Metaverse Credits?`,
        text2: `Note.. the upgrade will take up to 10 seconds to complete`,
        url: "/api/project/upgrade/request",
        method: "POST",
      },
      (data, res) => {
        //console.log(res);
        setDetailsLoading(false);
        if (res && res.success) {
          setTimeout(() => {
            upgradeRequestComplete(nft, res);
          }, 100);
        }

        if (res && !res.success) {
          viewContext.notification.show(res.message, "error", true);
        }
      }
    );
  }

  async function upgradeRequestRandomEarned(nft) {
    setDetailsLoading(true);

    viewContext.modal.show(
      {
        title: "Upgrade Item?",
        form: {
          nft_id: {
            type: "hidden",
            value: nft.id,
          },
          type: {
            type: "hidden",
            value: "random_earned",
          },
        },
        buttonText: "Upgrade Now",
        text: `Are you sure you want to upgrade a Random Stat on ${nft.name} for Free?`,
        text2: `Note.. the upgrade will take up to 10 seconds to complete`,
        url: "/api/project/upgrade/request",
        method: "POST",
      },
      (data, res) => {
        //console.log(res);
        setDetailsLoading(false);
        if (res && res.success) {
          setTimeout(() => {
            upgradeRequestComplete(nft, res);
          }, 100);
        }

        if (res && !res.success) {
          viewContext.notification.show(res.message, "error", true);
        }
      }
    );
  }

  async function upgradeRequest(nft, stat_name, cost, upgradeAction) {
    setDetailsLoading(true);
    viewContext.modal.show(
      {
        title: "Upgrade Item?",
        form: {
          nft_id: {
            type: "hidden",
            value: nft.id,
          },
          type: {
            type: "hidden",
            value: "stat",
          },
          stat_name: {
            type: "hidden",
            value: stat_name,
          },
        },
        buttonText: "Upgrade Now",
        text: `Are you sure you want to ${upgradeAction} on ${nft.name} for ${cost} Metaverse Credits?`,
        url: "/api/project/upgrade/request",
        method: "POST",
      },
      (data, res) => {
        //console.log(res);
        setDetailsLoading(false);
        if (res && res.success) {
          setTimeout(() => {
            upgradeRequestComplete(nft, res);
          }, 100);
        }

        if (res && !res.success) {
          viewContext.notification.show(res.message, "error", true);
        }
      }
    );
    setDetailsLoading(false);
  }

  async function Reveal(nft, projectData) {
    ///api/project/:project_id/reveal/:token_id
    //console.log(nft);
    //console.log(projectData);

    const res = await Axios.post(
      "/api/project/" +
        projectData.project.id +
        "/reveal/" +
        projectData.token.token_id,
      {
        nft_id: nft.id,
      }
    );
    viewContext.notification.show(res.data.message, "success", false);

    setTimeout(() => {
      refreshNFT(nft);
    }, 15000);
  }

  return (
    <Fragment>
      
      <NFTHolderNav
        nft_id={nft_id}
        nft_collection={nftData ? nftData.nftData.collection : null}
        center={!advanced}
      />
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      <Message
        closable
        messageID="Message_ManageNFT"
        title="Manage your NFT"
        text="Here you can see all the XP and other stats received from apps while using this collectible. To see every event sent in, click 'Events'."
        type="info"
      />

      {/*
      <div className="basis-1/4">
        <WalletMultiButton className='!bg-gray-900 hover:scale-105' />
      </div>
      */}

      {confettiOn && (
        <Confetti
          numberOfPieces={1000}
          tweenDuration={7000}
          width={width}
          height={height}
          onConfettiComplete={(e) => {
            confettiStopped();
          }}
          recycle={false}
        />
      )}
      {nftData && (
        <Animate>

            <Grid cols="3" key="buttons">
              <Button
                //className='!w-1/3'
                key="manual"
                small
                color={"blue"}
                text="Set Nickname"
                action={(e) => changeNickName(nftData.nftData)}
              />
              {NFTProjectData &&
                NFTProjectData.project.reveal_type === 2 &&
                NFTProjectData.revealed === 0 && (
                  <Button
                    key="reveal"
                    small
                    color={"green"}
                    text="Reveal"
                    action={(e) => Reveal(nftData.nftData, NFTProjectData)}
                  />
                )}
            
            <div className='text-sm'>
              <Card className={Style.smallRow}>
                <strong>Metaverse Credits: </strong>{" "}
                {currentUserCreditInfo && currentUserCreditInfo.creditBalance
                  ? `${currentUserCreditInfo.creditBalance.toLocaleString()}`
                  : "--"}
                &nbsp;&nbsp;&nbsp;<br/>
                <Link
                  title="Need More Metaverse Credits? Get some here!"
                  target="_blank"
                  url="/shop/token/play"
                  text="Get More Credits"
                />
                {/*
                <br />
                {canInscribe && (
                  <Button
                    className={Style.inscribeAllBtn}
                    tiny
                    action={(e) => inscribe("all")}
                    text={
                      nftData.nftData.level >= 10
                        ? `Inscribe All (${nftData.totalStatUninscribed.toLocaleString()} Credits)`
                        : `Inscribe All (Free)`
                    }
                  />
                )}
                  */}
              </Card>
              {!creditDiscount?.loading && creditDiscount.data > 0 && (
                <Card className={Style.smallRow}>
                  
                  
                    <>
                      {" "}
                      {`${
                        creditDiscount.data * 100
                      }% Discount on Credit-based items`}
                    </>
                      
                </Card>
              )}
              </div>

              <SocialShare
                className='-mr-8'
                url={`https://app.nftscribe.io/nft/view/${nft_id}`}
                description={`Check out my Level ${
                  nftData.nftData.xpStats.level
                } NFT${
                  nftData.nftData.nickname
                    ? " '" + nftData.nftData.nickname + "' "
                    : " "
                }on Scribe! `}
              />
              
            </Grid>

          
          {!detailsLoading && nftData && battlefieldLink && 
            <Card dark >
              <strong>
                Battlefield Link:&nbsp;
              </strong>
              <Link
                url={`${battlefieldLink}`}
                target="_blank"
                text={`${battlefieldLink}`}
              />
              <br/>
            </Card>
}
          

          <Grid cols="3">
            <Card
              shadow
              rounded
              //badge={nftData.openSeaData.status_name } badgeText={nftData.token_name }
              loading={detailsLoading}
              key="nftdetails1"
              className={Style.nftholder}
              sideBadges={nftData ? nftData.nftData.xp_buckets : []}
              restrictWidth
            >
              {!detailsLoading && nftData && (
                <Fragment>
                  <div className={Style.collection}>
                    {nftData.nftData.collection.name}
                  </div>

                  <div className={Style.nftname}>{nftData.nftData.name}</div>
                  <div id="divTokenID" className={ Style.nftname }>
                    Token #{ truncateString(nftData.nftData.token_id, 25) }
                  </div>

                  <div className={Style.nftname}>
                    {nftData.nftData.nickname ? (
                      "Name: " + nftData.nftData.nickname + " "
                    ) : (
                      <>&nbsp;</>
                    )}
                    {nftData.nftData.title !== "" ? (
                      "'" + nftData.nftData.title + "'"
                    ) : (
                      <>&nbsp;</>
                    )}
                  </div>

                  <Image
                    className={Style.nftImageLarge}
                    nftImage={true}
                    border={true}
                    source={
                      projectTokenMetaData
                        ? projectTokenMetaData.image + "?cache=false"
                        : nftData.nftData.image_url + "?cache=false"
                    }
                    alt={
                      projectTokenMetaData
                        ? projectTokenMetaData.image
                        : nftData.nftData.name
                    }
                  />

                  <ProgressBar
                    progress={(nftData.nftData.xpStats.progress * 100) + "%"}
                    label={
                      nftData.nftData.unlocked === 1
                        ? `Level: ${nftData.nftData.xpStats.level}  (${nftData.nftData.xp.toLocaleString()} of ${nftData.nftData.xpStats.nextLevelXP.toLocaleString()})`
                        : "Activate to Level Up"
                    }
                  />
                  {/*
                  <ProgressBar
                    raw
                    small
                    progress={
                      unAssignedXP !== "0"
                        ? nftData.nftData.progress_raw + "%"
                        : "0"
                    }

                    label={
                      nftData.nftData.unlocked === 1
                        ? unAssignedXP === "0"
                          ? "(No Uninscribed XP)"
                          : `Uninscribed XP: ${unAssignedXP}   Level: ${nftData.nftData.xpStatsRaw.level}`
                        : "Activate to Level Up"
                    }

                    //label={nft.unlocked === 1 ? `Uninscribed XP: ${unAssignedXP} ${unAssignedXP > 0 ? `Level ${nft.xpRawStats.level}` : ''}` : 'Activate to Level Up'}
                  />
                  */}
                </Fragment>
              )}
            </Card>
            <Card
              shadow
              rounded
              //badge={nftData.openSeaData.status_name } badgeText={nftData.token_name }
              smallHeader
              loading={detailsLoading}
              key="nftdata2"
              className={Style.statCardOverflow}
              title="Official Metadata"
            >
              {!detailsLoading && nftData &&  (
                <Fragment>
                  <small><strong>Image Hash: </strong>{nftData.nftData.image_hash}</small>
                  {nftData.nftAssetData.traits && (
                                projectTokenMetaData ? (
                                  <Table
                                    className="restrict-width"
                                    cellStyle="cell_small"
                                    headerStyle="header_small"
                                    data={projectTokenMetaData.attributes}
                                    loading={detailsLoading}
                                    show={["trait_type", "value"]}
                                    //actions={{ edit: editUser, delete: deleteUser, email: true }}
                                  />
                                ) : (
                                  <Table
                                    className="restrict-width"
                                    cellStyle="cell_small"
                                    headerStyle="header_small"
                                    data={nftData.nftAssetData.fixedTraits}
                                    loading={detailsLoading}
                                    show={["trait_type", "value"]}
                                    //actions={{ edit: editUser, delete: deleteUser, email: true }}
                                  />
                                )
                      )
                    }
                </Fragment>
              )}
            </Card>
            <Card
              smallHeader
              shadow
              rounded
              //badge={nftData.openSeaData.status_name } badgeText={nftData.token_name }
              loading={detailsLoading}
              key="nftdata3"
              className={Style.nftholder}
              restrictWidth
              title="Info"
            >
              {!detailsLoading && nftData && (
                <Fragment>
                  <div className={Style.collection}>
                    <p>
                      <strong>wallet_address: </strong>
                      <br />
                      {nftData.nftData.wallet_address}
                    </p>
                    <br />
                    <p>
                      <strong>asset_contract: </strong>
                      <br />
                      {nftData.nftData.asset_contract}
                    </p>
                    <br />
                    <p>
                      <strong>token_id: </strong>
                      <br />
                      {nftData.nftData.token_id}
                    </p>
                    <br />
                    {nftData.nftAssetData && nftData.nftAssetData.permalink && (
                      <Link
                        url={`${nftData.nftAssetData.permalink}`}
                        target="_blank"
                        text="OpenSea Listing"
                      />
                    )}
                  </div>
                </Fragment>
              )}
            </Card>
          </Grid>

          {!detailsLoading && nftData && nftData.level === 0 && (
            <Message
              closable
              messageID="Message_Stats"
              color="transparent"
              title="Stats"
              text={"Stats your NFT is collecting will be listed below"}
            />
          )}

          

          

          <Grid cols="5">
            {!detailsLoading &&
              nftData &&
              nftData.projectTokenData &&
              nftData.projectTokenData.project.upgradable === 1 &&
              (showUpgrades || (user.data && userFlags.includes("3"))) &&
              upgradeOptions && (
                <Card
                  key="upgradeCard"
                  shadow
                  rounded
                  smallHeader
                  title="Upgrade"
                  className={Style.nftStatCardCenterOverflow}
                >
                  <div className={Style.nftStatCenter}>
                    Total Current Points: {upgradeOptions.tokenPoints}
                  </div>

                  {nftData.projectTokenData.token.earned_upgrades > 0 && (
                    <div className={Style.nftStatCenter}>
                      Free Upgrades Remaining:{" "}
                      {nftData.projectTokenData.token.earned_upgrades}
                    </div>
                  )}

                  {upgradeOptions.statUpgradeOptions.length === 0 && (
                    <strong>No Upgrades Available</strong>
                  )}
                  {upgradeOptions.statUpgradeOptions.map(
                    (statUpgradeOption, index) => {
                      if (statUpgradeOption.stat_op === "random") {
                        return (
                          <Button
                            key={statUpgradeOption.stat_name}
                            className={Style.nftUpgradeButton}
                            tiny
                            variant="contained"
                            action={(e) =>
                              upgradeRequestRandom(
                                nftData.nftData,
                                upgradeOptions.randomPointCost.toLocaleString()
                              )
                            }
                            text={`Random Stat: ${upgradeOptions.randomPointCost.toLocaleString()} Credits`}
                          />
                        );
                      } else if (
                        statUpgradeOption.stat_op === "random_earned"
                      ) {
                        return (
                          <Button
                            key={statUpgradeOption.stat_name}
                            className={Style.nftUpgradeButton}
                            tiny
                            variant="contained"
                            action={(e) =>
                              upgradeRequestRandomEarned(nftData.nftData)
                            }
                            text={`${statUpgradeOption.stat_name}`}
                          />
                        );
                      } else {
                        return (
                          <Button
                            key={statUpgradeOption.stat_name}
                            className={Style.nftUpgradeButton}
                            tiny
                            variant="contained"
                            action={(e) =>
                              upgradeRequest(
                                nftData.nftData,
                                statUpgradeOption.stat_name,
                                statUpgradeOption.cost.toLocaleString(),
                                statUpgradeOption.action_name
                              )
                            }
                            text={`${
                              statUpgradeOption.stat_name
                            }: ${statUpgradeOption.cost.toLocaleString()} Credits`}
                          />
                        );
                      }
                    }
                  )}
                </Card>
              )}

            {!detailsLoading &&
              nftData &&
              promotions &&
              promotions.length > 0 &&
              showPromotions && (
                <Card
                  key="promotionsCard"
                  shadow
                  rounded
                  smallHeader
                  title="Earned Rewards"
                  className={Style.nftStatCardCenterOverflow}
                >
                  {promotions.map((promotion, index) => {
                    return (
                      <>
                        <div
                          key="reward_title"
                          className={"text-center text-xs"}
                        >
                          {promotion.title}
                        </div>
                        <div
                          key="reward_code"
                          className={"text-center text-xs"}
                        >
                          Claim Code: <strong>{promotion.code}</strong>
                        </div>
                        <Button
                          key={promotion.id}
                          className={Style.nftUpgradeButton}
                          tiny
                          variant="contained"
                          action={(e) => viewPromotion(promotion)}
                          text={`Claim Now`}
                        />
                      </>
                    );
                  })}
                </Card>
              )}

            {!detailsLoading &&
              nftData &&
              nftData.nftData.application_buckets.map((stat, index) => {
                return (
                  <Card
                    key={stat.id}
                    shadow
                    rounded
                    smallHeader
                    title={stat.application_name}
                    className={Style.nftStatCardCenter}
                  >
                    <Image
                      className={Style.applicationImage}
                      nftImage={true}
                      border={true}
                      source={stat.logo_square_url}
                      alt={stat.application_name}
                    />
                    <div className={Style.nftStatCenter}>
                      <strong>XP:{" "}
                      {parseInt(stat.event_total).toLocaleString()}</strong>
                      {/*
                      <br />
                      {stat.uninscribedValue > 0 && (
                        <>
                          Uninscribed {stat.stat_name.toUpperCase()}:{" "}
                          {stat.uninscribedValue.toLocaleString()}
                        </>
                      )}
                      */}
                    </div>
                    
                    
                    {1==3 && stat.uninscribedValue && stat.uninscribedValue > 0 ? (
                      !hideInscribe && (
                        <Fragment>

                          <Button
                            className={Style.nftButton}
                            tiny
                            variant="contained"
                            action={(e) => {
                              !creditInscribes.loading &&
                              creditInscribes.data === 1
                                ? inscribeCheck(stat)
                                : inscribeCheck(stat);
                            }}
                            text={
                              nftData.nftData.level >= 10
                                ? `Inscribe (${
                                    !creditInscribes.loading &&
                                    creditInscribes.data === 1
                                      ? stat.inscribeCreditCost.toLocaleString() +
                                        " Credits)"
                                      : "Free)"
                                  } `
                                : `Inscribe (Free)`
                            }
                            //text={`Inscribe (Free)`}
                          />
                          {payLink && (
                            <Link url={`${payLink}`} text="Pay Now" />
                          )}
                          <div id="qr-code"></div>
                        </Fragment>
                      )
                    ) : (
                      <></>
                      /*<div className={Style.nftStatCenter}>
                        <strong>
                          --Fully Inscribed--
                          <br />
                        </strong>
                      </div>
                      */
                    )}
                  </Card>
                );
              })}
          </Grid>
          {!detailsLoading && nftData && !nftData.nftData.square && (
            <Message
              text="This NFT is not square, which can cause problems with profile pic matching. Try Refreshing Images and then use the 'Get PFP' button below for an image which is guaranteed to work."
              type="warning"
            />
          )}
          {!detailsLoading && nftData && (
              <Card dark title="Images" restrictWidthWider>
                <p>Each image opens in a new window for easy saving. If you experience issues, click "Refresh Images"</p>
                <Grid cols="4">
                  <Button
                    tiny
                    color="blue"
                    text="Get PFP Image"
                    action={(e) => viewImage("normal")}
                  />
                  {/*<Button
                    tiny
                    color="blue"
                    text="Thumbnail"
                    action={(e) => viewImage("thumbnail")}
                  />
                  
                  <Button
                    tiny
                    color="blue"
                    text="Square"
                    action={(e) => viewImage("square")}
                  />
                  <Button
                    tiny
                    color="blue"
                    text="Get Round Image"
                    action={(e) => viewImage("round")}
                  />*/}
                  <Button
                    tiny
                    color="green"
                    text="Refresh Images"
                    action={(e) => refreshNFT(nftData.nftData)}
                  />
                </Grid>
                
                </Card>
            )}
          {advanced && ( 
            <Card title="Utilities">
              <Grid cols="3">
                <Button
                  small
                  color="red"
                  text="Deactivate"
                  action={(e) => deactivateNFT(nftData.nftData)}
                />
                <Button
                  small
                  color="green"
                  text="Refresh Metadata"
                  action={(e) => refreshNFT(nftData.nftData)}
                />
                {!detailsLoading && nftData && 1===3 & (
                  <Button
                    small
                    color="green"
                    text="Force Profile Match"
                    action={(e) => forceMatch(nftData.nftData)}
                  />
                )}
              </Grid>
              
            </Card>
          )}

            

          <Feedback />
        </Animate>
      )}
      </div>
    </Fragment>
  );
}
