/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

 import React, {
    useEffect, useState, useContext, useCallback, useRef,  } from "react";
  
  import {
    Card, ViewContext, Stat, ProgressBar, Image, Button, Link, SocialShare, Search, Message, Grid, Animate, Feedback, useAPI, useNavigate,
    DashboardNav, AuthContext, TabView, SignIn} from "components/lib";
  
  import { useWeb3React } from "@web3-react/core";
  import { /*useConnection,*/ useWallet } from "@solana/wallet-adapter-react";
  import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
  //import { Keypair, SystemProgram, Transaction } from '@solana/web3.js';
  import Axios from "axios";
  import { /*ConsoleView,*/ isMobile } from "react-device-detect";
  import Style from "./dashboard.tailwind.js";
  import { CreditHistory } from "views/shop/credits.js";
  import { PlayHistory } from "views/shop/play.js";
  
  let lastSearch = "";
  
  export function RewardsDashboard(props) {
    const pageSize = 5;
    
    const viewContext = useContext(ViewContext);
    const authContext = useContext(AuthContext);
    const user = useAPI("/api/user", authContext);
    const navigate = useNavigate();
    //const stats = useAPI("/api/demo/stats");
    const userSite = useAPI("/api/site/dashboard",authContext);
    const playXPConstant = useAPI("/api/var/play_xp_modifier");
    const creditDiscount = useAPI("/api/var/credit_discount");
    const currentUserPlayInfo = useAPI("/api/play_balance",authContext);
    const currentUserBalanceInfo = useAPI("/api/shop/credits",authContext);
    const userRewardsInfo = useAPI("/api/user/rewards",authContext);
    const XPList = useAPI("/api/application_xp");
    const [advanced, setAdvanced] = useState(false);
  
    function isMobileDevice() {
      return "ontouchstart" in window || "onmsgesturechange" in window;
    }
  
    //const progress = useAPI('/api/demo/progress');
    //const table = useAPI('/api/demo/users/list');
    //const userChart = useAPI('/api/demo/users/types');
    //const revenueChart = useAPI('/api/demo/revenue');
    const markdown = useAPI("/api/markdown/dashboard/wallet");
  
    const { active, account, chainId } = useWeb3React();
    const [nftBaseList, setNFTBaseList] = useState(null);
    const [nftList, setNFTList] = useState(null);
    const [nftEvents, setNFTEvents] = useState(null);
    const [recentEvent, setRecentEvent] = useState(null);
    const [todayXP, setTodayXP] = useState(0);
    const [todayApplications, setTodayApplications] = useState(0);
    const [userLevelInfo, setUserLevelInfo] = useState(null);
    const [lastHourInfo, setLastHourInfo] = useState(null);
    const [referralURL, setReferralURL] = useState(null);
    //const [hasActiveNFTs, setHasActiveNFTs] = useState(false);
    //const [nftImages, setNFTImages] = useState(null);
    //const [defaultNFTImageIndex, setDefaultNFTIndex] = useState(0);
    const [existingAccount, setAccount] = useState(null);
    const [userHasSolanaWallet, setUserHasSolanaWallet] = useState(null);
    const [userSolanaWallet, setUserSolanaWallet] = useState(null);
  
    const [foundTwitterProfile, setFoundTwitterProfile] = useState(false);
    const [search, setSearch] = useState("");
    const [currentSolanaKey, setCurrentSolanaKey] = useState(null);
    const [userXPModifier, setCurrentUserXPModifier] = useState(1);
    const [currentPlayInfo, setCurrentPlayInfo] = useState(null);
  
    const [showRewards, setShowRewards] = useState(true);
    const [showRecruit, setShowRecruit] = useState(true);
    const [showStats, setShowStats] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);
    const [maxPageIndex, setMaxPageIndex] = useState(0);
  
    const [currentUserCreditInfo, setCurrentUserCreditInfo] = useState(null);
  
    //Solana Wallet
    const { publicKey, sendTransaction } = useWallet();
    const solanaTimerRef = useRef(null);
  
    function abbreviateNumber(value) {
      var newValue = value;
      if (value >= 1000) {
          var suffixes = ["", "K", "M", "B","T"];
          var suffixNum = Math.floor( (""+value).length/3 );
          var shortValue = '';
          for (var precision = 2; precision >= 1; precision--) {
              shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
              var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
              if (dotLessShortValue.length <= 2) { break; }
          }
          if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
          newValue = shortValue+suffixes[suffixNum];
      }
      return newValue;
  }
  
    async function checkSolanaAccountChange() {
      if (publicKey) {
        if (currentSolanaKey !== publicKey) {
          console.log("Solana Account has changed");
        }
  
        clearTimeout(solanaTimerRef.current);
        solanaTimerRef.current = setTimeout(() => {
          console.log("Non-Initial solanaCheckTimeout timeout!");
          checkSolanaAccountChange();
        }, 1000);
      }
    }
  
    /*
    useEffect(() => {
      solanaTimerRef.current = setTimeout(() => {
        console.log('Initial solanaCheckTimeout timeout!')
        checkSolanaAccountChange();
      }, 1000);
  
      return () => clearTimeout(solanaTimerRef.current);
    }, []);
    */
  
    useEffect(() => {
      //console.log(viewContext);
        setAdvanced(viewContext.isAdvanced);
    }, [viewContext]);
    
    useEffect(() => {
      if (userSite?.loading) {
      } else {
        //console.log(userSite);
        if (userSite.data) {
          if (userSite.data.message) {
            //console.log(userSite.data.message);
            viewContext.notification.show(userSite.data.message, "success", false);
          }
        }
      }
    }, [userSite]);
  
    useEffect(() => {
      if (XPList?.loading) {
        //console.log(XPList);
      } else {
        //console.log(XPList);
      }
    }, [XPList]);
  
    useEffect(() => {
      if (userRewardsInfo?.loading) {
        //console.log(userRewardsInfo);
      } else {
        //console.log(userRewardsInfo);
      }
    }, [userRewardsInfo]);
  
    useEffect(() => {
      //We actually don't usually have a publicKey as the user would have already added their Solana Wallet
      if (publicKey) {
        console.log("Solana Public Key: " + publicKey);
        setCurrentSolanaKey(publicKey);
        const res = Axios.post("/api/user/web3", {
          wallet_address: publicKey,
          wallet_type: "rewards",
          chain: "SOL",
        }).then((res) => {
          //console.log(res);
          if (res.data) {
            res.data.success
              ? viewContext.notification.show(res.data.message, "success", true)
              : res.data.message &&
                viewContext.notification.show(res.data.message, "error", true);
          }
        });
      }
    }, [publicKey]);
  
    useEffect(() => {
      if (playXPConstant?.loading) {
        //console.log(playXPConstant);
      } else {
        //console.log(playXPConstant);
      }
    }, [playXPConstant]);
  
    
  
    useEffect(() => {
      if (currentUserBalanceInfo?.loading) {
        //console.log(currentUserBalanceInfo);
      } else {
        //console.log(currentUserBalanceInfo);
      }
    }, [currentUserBalanceInfo]);
  
    useEffect(() => {
      if (currentUserPlayInfo?.loading) {
        //console.log(currentUserPlayInfo);
      } else {
        //console.log(currentUserPlayInfo);
        if (currentUserPlayInfo.success) {
          //console.log(currentUserPlayInfo);
          if (playXPConstant.success) {
            if (currentUserPlayInfo.data >= 500) {
              //console.log(playXPConstant);
              let theModifier = playXPConstant.data * currentUserPlayInfo.data;
              theModifier = theModifier.toFixed(2);
              //console.log(theModifier);
              setCurrentUserXPModifier("+" + theModifier.toLocaleString() + "%");
            } else {
              setCurrentUserXPModifier("0\r\n(500 Minimum)");
            }
          }
        }
      }
    }, [currentUserPlayInfo, playXPConstant]);
  
    useEffect(() => {
      if (user && user.data) {
        //console.log(user);
        //Check SOL Wallets
        let userWalletData = user.data.wallets;
        let hasSol = false;
        let solWallet = null;
        let rewardsWallet = null;
        userWalletData &&
          userWalletData.forEach((wallet) => {
            if (wallet.chain === "SOL") {
              hasSol = true;
              if (wallet.wallet_type === "rewards") {
                rewardsWallet = wallet.wallet_address;
                solWallet = wallet.wallet_address;
              } else {
                if (!rewardsWallet && !solWallet) {
                  solWallet = wallet.wallet_address;
                }
              }
            }
          });
        setUserSolanaWallet(solWallet);
        setUserHasSolanaWallet(hasSol);
        //setUserHasSolanaWallet(false);
  
        if (!hasSol && 1===3) {
          viewContext.modal.show(
            {
              title: "Add Solana Wallet",
              buttonText: "OK",
              destructive: false,
              text: "Our Activity Rewards program is active! To participate, please connect your Solana wallet using the 'Select Wallet' button.",
              link: "https://nftscribe.io/rewards",
              linkText: "Click here for more information!",
            },
            (res) => {
              //console.log(res);
              //viewContext.notification.show('Space being tracked!', 'success', true, 'toast', 'heart');
              // destory user
              //Event('closed_account');
              //localStorage.clear();
              //navigate('/signup');
            }
          );
        } else {
          //Get PLAY Balance
        }
      }
    }, [user]);
  
    
  
    useEffect(() => {
      async function getUserEvents() {
        if (user.data) {
          const resCredits = await Axios.get(`/api/shop/credits`);
          //console.log(resCredits.data.data);
          setCurrentUserCreditInfo(resCredits.data.data);
        }
      }
  
      getUserEvents();
    }, [user]);
  
    /*
    useEffect(() => {
      //console.log(user);
      
      //user?.data?.referral_url && console.log(user.data.referral_url);
  
      //console.log(chainId);
      if(active){
        //console.log("active");
        async function changeAccount(){
          //console.log("Account changed");
          setAccount(account);
        }
  
        //console.log("CHAIN: " + chainId);
  
        if(existingAccount !== account){
          changeAccount();
          getNFTsNEW();
        }
      } else {
        //console.log("Not active");
        setNFTList(null);
        setAccount(null);
      }
      
    },[existingAccount, setAccount, account, active, nftList, chainId]);
    */
    if(!authContext.user){
      return (
        <Card dark700 center restrictWidthWide>
          <SignIn title="Signin Required" text="You must signin to use this feature" />
        </Card>
      );
    }

  
    return (
      <>
      {user && user.data && <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>}
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      
        {!creditDiscount.loading && creditDiscount.data !== 0 && (
          <Message
            messageID="Discount_Credits"
            title="Credit Discount Active"
            text={
              "Anything which costs credits (Inscribes, NFT Upgrades) is " +
              creditDiscount.data * 100 +
              "% off!"
            }
            type="success"
          />
        )}
   
        <Grid cols="2">
        <Card dark restrictWidth className={Style.smallRow}>
          
          <strong>Metaverse Credits: </strong>{" "}
          {currentUserCreditInfo && currentUserCreditInfo.creditBalance
            ? `${currentUserCreditInfo.creditBalance.toLocaleString()}`
            : "--"}
          &nbsp;&nbsp;&nbsp;

          &nbsp;&nbsp;&nbsp;
          <Link
            title="Need More Metaverse Credits? Get some here!"
            target="_blank"
            url="/shop/token/play"
            text="Get More Credits"
          />
        </Card>
        <Card dark name="rewards_info" center className={"bg-white"}>
          Rewards include any Metaverse Credits or $PLAY you have earned through Scribe.
        </Card>
        </Grid>
        

        <Card name="rewards" center className={"bg-slate-200"}>
          <div>
            <Grid cols="5">
              <div>
                <strong>Metaverse Rewards&nbsp;&nbsp;</strong>
                <Link
                  url={"https://nftscribe.io/rewards"}
                  text={"(Details)"}
                  target={"_new"}
                />
              </div>
              <div>
                <strong>$PLAY Balance: </strong>
                {currentUserPlayInfo.loading
                  ? "--"
                  : currentUserPlayInfo.data
                  ? currentUserPlayInfo.data.toLocaleString()
                  : "0"}
              </div>
              {/*<div><strong>Global $PLAY-to-XP Ratio:</strong><br/>{playXPConstant.loading ? '--' : playXPConstant.data}</div>*/}
              <div>
                <strong>$PLAY Bonus XP: </strong>
                {currentUserPlayInfo.loading ? "--" : userXPModifier}
              </div>
              <div>
                <Button
                  tiny
                  className={"float-left p-8 mx-2 ..."}
                  url="https://play.nftscribe.io"
                  text="Buy $PLAY"
                  target="_new"
                />
              </div>
              <div></div>
            </Grid>
          </div>

          {!userHasSolanaWallet && (
            <div className={isMobile ? "mr-4" : "float-left mr-4"}>
              <span className={"text-sm"}>Solana Wallet (for Rewards)</span>
              <br />
              <WalletMultiButton className="!bg-gray-900 hover:scale-105" />
              {isMobile && (
                <strong className={"text-sm"}>
                  Please use your Mobile Wallet's browser.
                </strong>
              )}
            </div>
          )}

          <Grid cols="3">
            {userHasSolanaWallet && (
              <div>
                <Stat
                  loading={false}
                  value={`${userSolanaWallet.substring(
                    0,
                    4
                  )}...${userSolanaWallet.substring(
                    userSolanaWallet.length - 4
                  )}`}
                  label="Rewards Account Connected (SOL)"
                  icon="credit-card"
                />
              </div>
            )}
            <div>
              <Stat
                small
                loading={userRewardsInfo.loading}
                value={
                  userRewardsInfo.loading
                    ? "Pending"
                    : userRewardsInfo.data
                    ? userRewardsInfo.data.eligibility.xp.message
                    : "--"
                }
                label="XP Rewards Eligibility"
              />
            </div>
            
            <div>
              <Stat
                loading={userRewardsInfo.loading}
                value={
                  userRewardsInfo.loading
                    ? "--"
                    : userRewardsInfo.data
                    ? userRewardsInfo.data.eligibility.overall.message
                    : "--"
                }
                label="Weekly Rewards Earned"
                icon="dollar-sign"
              />
            </div>
          </Grid>
        </Card>
        

        <TabView dark name='Rewards' labels={['Metaverse Credits', '$PLAY']}>
          <Card dark ><CreditHistory hideMenu /></Card>
          <Card dark><PlayHistory /></Card>
          
          
        </TabView>
        
        {/*
        <Card name='revenues' title='Revenue'>
          <Chart
            type='line'
            legend
            loading={ revenueChart.loading }
            data={ revenueChart.data }
            color={['red', 'blue']}
          />
        </Card>
  
        <Grid cols='2'>
          <Card title='Goals' loading={ progress.loading }>
            { progress?.data?.map(item => {
  
              return (
                <ProgressBar
                  key={ item.label }
                  label={ item.label }
                  progress={ item.value }
                />
              );
  
            })}
          </Card>
          <Card title='User Types'>
            <Chart
              type='pie'
              legend={ true }
              data={ userChart.data }
              loading={ userChart.loading }
              color='purple'
            />
          </Card>
        </Grid>
  
        {/*<Card title='Users' last>
          <Table
            search
            data={ table.data }
            loading={ table.loading }
            badge={{ col: 'plan', color: 'blue' }}>
          </Table>
        </Card>
          */}
          </div>
        <Feedback />
      </>
    );
  }
  