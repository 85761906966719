import React, { useState, useEffect } from 'react';
import { Label, ClassHelper } from 'components/lib';
import Style from './switch.tailwind.js';

export function Switch(props){

  
  // state
  const [on, setOn] = useState(props.default && props.default !== "false" ? true : false);

  useEffect(() => {
    if(props.default && props.default !== "false"){
      setOn(props.default);
    }
  }, []);

  function toggle(){

    setOn(!on);
    props.onChange(props.name, !on, true);

  }

  if(props.default && props.default !=="false"){
    //console.log(props);
    //console.log("Should be on");
  }

  let trackStyle;
  let handleStyle;

  if(props.small){
    //console.log("on :" + on);
    if(on){
      trackStyle = ClassHelper(Style, {
        trackSmall: true, 
        trackOnSmall: true,
        trackOffSmall: false
      });
      handleStyle = ClassHelper(Style, {
        handleSmall: true, 
        handleOnSmall: true,
        handleOffSmall: false,
      });
    } else {
      trackStyle = ClassHelper(Style, {
        trackSmall: true, 
        trackOnSmall: on,
        trackOffSmall: !on
      });
      handleStyle = ClassHelper(Style, {
        handleSmall: true, 
        handleOnSmall: on,
        handleOffSmall: !on,
      });
    }
    
  } else {
    trackStyle = ClassHelper(Style, {
      track: true, 
      trackOn: on,
      trackOff: !on
    });
    handleStyle = ClassHelper(Style, {
      handle: true, 
      handleOn: on,
      handleOff: !on
    });
  }

  //console.log(handleStyle);
  

  return(
    <div className={ props.small? Style.switchSmall : Style.switch}>

      <Label
        text={ props.label }
        required={ props.required }
        className={ props.small? Style.labelSmall : Style.label }
      />

      <div className={ trackStyle } onClick={ toggle } >
        <div className={ handleStyle }></div>
      </div>
    </div>
  );
}