/***
*
*   ONBOARDING
*   Flow to help users set up the app, accepts multiple views/steps 
*   On finish/cancel, the user will be marked as onboarded
*
*   PROPS
*   views: array of views to render containing keys: 
*     name, description and component to render
*
**********/

import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Animate, CheckList, Button, Logo, useNavigate, Event, Card, Link } from 'components/lib';
import Style from './battlefield.tailwind.js';

export function BattlefieldConfig(props){

  console.log(props);
  //const navigate = useNavigate();
  //const hash = window.location.hash;
  const [battlefieldLink, setBattlefieldLink] = useState(null);
  //const [currentUserCreditInfo, setCurrentUserCreditInfo] = useState(null);
  const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

  useEffect(() => {
    console.log("Initial load");
    let bfSlug = props.nftData.slug;
    let nftName = props.nftData.nickname;
    if(bfSlug == null){
      if(nftName == null){
        nftName = props.nftData.name;
      }
      bfSlug = slugify(nftName);
    }
        
    setBattlefieldLink("https://app.nftscribe.io/game/" + bfSlug);

    //console.log(slugify('Battlefield #4'));
  }, [props])

     
  return (
    <Animate type='pop-center'>
      <div className={ Style.onboarding }>

        <section className={ Style.sidebar }>

          <Card className={Style.smallRow}>
          <strong>Battlefield Link:&nbsp;&nbsp;</strong>
            {battlefieldLink && 
              <Link 
                url={battlefieldLink} 
                text={battlefieldLink}
                target={"_new"} 
              />
            }
          </Card>  
        </section>
    </div>
    </Animate>
  );
}