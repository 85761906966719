/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext} from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Card, Stat, ProgressBar, Chart,  Button, Image, Search, SignIn, 
  Message, Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, AuthContext, WalletConnect, DashboardNav } from 'components/lib';

import { useWeb3React } from "@web3-react/core";

//Solana Support
import {ConsoleView, isMobile} from 'react-device-detect';
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { Keypair, SystemProgram, Transaction } from '@solana/web3.js';

import Axios from 'axios';
import Style from './nft.tailwind.js';
import { faCropSimple } from '@fortawesome/free-solid-svg-icons';

let lastSearch = '';

export function NFTList(props){
  const pageSize = 12;
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const user = useAPI("/api/user", authContext);
  const navigate = useNavigate();
  const stats = useAPI('/api/demo/stats');
  //const progress = useAPI('/api/demo/progress');
  //const table = useAPI('/api/demo/users/list');
  //const userChart = useAPI('/api/demo/users/types');
  //const revenueChart = useAPI('/api/demo/revenue');

  const { active, account, chainId } = useWeb3React();
  const [nftList, setNFTList] = useState(null);
  const [nftBaseList, setNFTBaseList] = useState(null);
  const [nftCollections, setNFTCollections] = useState(null);
  const [hideUnlock, setHideUnlock] = useState(false);
  //const [nftImages, setNFTImages] = useState(null);
  //const [defaultNFTImageIndex, setDefaultNFTIndex] = useState(0);
  const [existingAccount, setAccount] = useState(null);
  const [currentChain, setCurrentChain] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);
  const [advanced, setAdvanced] = useState(false);
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const [processing, setProcessing] = useState(false);
    
  //Solana Wallet
  const { publicKey, sendTransaction } = useWallet();
  const [search, setSearch] = useState('');

  
  const urlParams = new URLSearchParams(window.location.search);
  let forceWallet = urlParams.get("wallet");
  //console.log("forceWallet " + forceWallet)

  function goKadena() {
    viewContext.modal.show(
      {
        title: "Import Kadena Tokens",
        form: {
          account: {
            label: "Kadena Wallet Address",
            type: "text",
            required: true,
            //value: "k:b8dbe0b6c7fe80b92ab648d5e758882e750114f4ffac4188ddf52e5bfc1a9d9c"
          },
        },
        buttonText: "Go!",
        url: "/api/nft/get_kadena_list",
        method: "POST",
        destructive: false,
      },
      (res, data) => {
        //console.log(res);
        //console.log(data);
        let nfts = data.nfts;
        console.log(nfts);
        let collections = [];
        forceWallet = true;
        setCurrentChain("kadena");
        let maxPageNum = Math.floor(data.nfts.length / pageSize);
        //console.log("maxPageNum");
        //console.log(maxPageNum);
        setMaxPageIndex(maxPageNum - 1);
        setPageIndex(0);
        setNFTBaseList(data.nfts);
        if(nfts && nfts.length > 0){
          nfts.forEach((nftAsset, index) => {
            //console.log(nftImage);
            nftAsset.index = index;
            if(!collections.includes(nftAsset.collectionID)){
              collections.push(nftAsset.collectionID);
            }
          });
        }
        

        setNFTList(nfts);
        //console.log(collections);
        setNFTCollections(collections);
    
        
        
      }
    );
  }

  function selectNFT(nft) {
    const updatedSelectedNFTs = [...selectedNFTs];
    console.log(nft);
  
    const index = updatedSelectedNFTs.findIndex(item => item.id === nft.id);
    if (index !== -1) {
      // NFT already exists in the selectedNFTs array, remove it
      updatedSelectedNFTs.splice(index, 1);
      nft.selected = false;
    } else {
      // NFT doesn't exist, add it with selected = true
      nft.selected = true;
      updatedSelectedNFTs.push(nft);
      console.log("Selecting");
    }
  
    setSelectedNFTs(updatedSelectedNFTs);
  }
  
  
  function truncateString(theString, truncLength = 50) {
    let retString = theString;
    if (theString.length > truncLength) {
      let strLen = theString.length;
      let string1 = theString.substring(0, truncLength);
      retString = string1 + "...";
    }
    return retString;
  }
  

  function chainName(){
    let theChain = '';
    let theChainName = 'ETH, Polygon, Cronos, Avalanche, Harmony Wallet';
    //console.log("chainID: " + chainId);
    switch(chainId){
      case 1: theChain = 'eth'; theChainName = 'Ethereum Mainnet'; break;
      case 3: theChain = 'eth'; theChainName = 'Ropsten Test'; break;
      case 4: theChain = 'eth'; theChainName = 'Rinkeby Test'; break;
      case 5: theChain = 'eth'; theChainName = 'Goerli Test'; break;
      case 42: theChain = 'eth'; theChainName = 'Kovan Test'; break;
      case 56: theChain = 'bnb'; theChainName = 'Binance Smart Chain'; break;
      case 137: theChain = 'matic'; theChainName = 'Polygon Mainnet'; break;
      case 25: theChain = 'cro'; theChainName = 'Cronos Mainnet'; break;
      case 100: theChain = 'xdai'; theChainName = 'Gnosis'; break;
      case 43114: theChain = 'avax'; theChainName = 'Avalanche C'; break;
      case 1666600000: theChain = 'harmony'; theChainName = 'Harmony Mainnet'; break;
      default: theChain = 'eth';
    }
    //console.log(theChain);
    return theChainName;
  }

  async function getNFTs(whichChain, whichAccount){
    console.log("getNFTs");
    //let nftFilter=[]; 
    
    console.log("whichChain: " + whichChain);
    console.log("currentChain: " + currentChain);
    console.log("chainId: " + chainId);
    console.log("whichAccount: " + forceWallet ? forceWallet : whichAccount);
    setNFTBaseList(null);
    setNFTList(null);
      //console.log("getNFTs GO");
      let url = '/api/nft/os_list';
      switch(whichChain){
        case 'eth':
          url = '/api/nft/os_list'; 
          break;
        case 'sol':
            url = '/api/nft/solana_list'; 
            break;
        case 'matic':
            url = '/api/nft/matic_list'; 
            break;
        case 'cro':
          url = '/api/nft/cro_list'; 
          break;
        case 'avax':
          url = '/api/nft/avax_list'; 
          break;
        case 'harmony':
          url = '/api/nft/os_list'; 
          break;
        case 'kadena':
          url = '/api/nft/get_kadena_list'; 
          break;
        default: url = '/api/nft/os_list';
      }

      
      
      const res = await Axios.post(url, { 
        chain: whichChain,
        account: forceWallet ? forceWallet : whichAccount,
        //publisher_id: publisher.id,
        //nft_template_id: nftFilter,
        chainId: whichChain,
        force: forceWallet
      });
      console.log(res.data.data.nfts);
      //console.log(res.data.data.nfts.length);
      let maxPageNum = Math.floor(res.data.data.nfts.length / pageSize);
      //console.log("maxPageNum");
      //console.log(maxPageNum);
      setMaxPageIndex(maxPageNum - 1);
      setPageIndex(0);
      setNFTBaseList(res.data.data.nfts);
      
      
      let collections = [];
  
  
      res.data.data.nfts.forEach((nftAsset, index) => {
        //console.log(nftImage);
        nftAsset.index = index;
        if(!collections.includes(nftAsset.collection.slug)){
          collections.push(nftAsset.collection.slug);
        }
      });
      setNFTList(res.data.data.nfts);
      //console.log(collections);
      setNFTCollections(collections);
    
  }

  useEffect(() => {
    //console.log(viewContext);
    setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);

  useEffect(() => {
    if(nftBaseList){
      //console.log("Show Locked " + showLocked);
      if(search !== '' || (lastSearch.toLowerCase() !== search.toLowerCase())){
        if(lastSearch.toLowerCase() !== search.toLowerCase()){
          //console.log(search);
          let list = nftBaseList;
          //console.log(list);
          let thisSearch = search.toLowerCase();
          let shortList = list.filter(function (nft) { 
            //console.log(creator);
            return (
              nft?.name.toLowerCase().indexOf(thisSearch) !== -1 ||
              nft?.collection?.name.toLowerCase().indexOf(thisSearch) !== -1 ||
              (nft?.nickname && nft?.nickname.toLowerCase().indexOf(thisSearch) !== -1)
            )
          });
          
          lastSearch = thisSearch;
          setNFTList(shortList)
        }
        
      } 
    }
    
  }, [search, nftBaseList]);


  useEffect(() => {
    
    
      if(active){
        //console.log("active");
        //console.log(chainId);

        //supportedChainIds: [1, 3, 4, 5, 42, 137, 25, 100 ],
        let theChain = 'eth';
        switch(chainId){
          case 1: theChain = 'eth'; break;
          case 3: theChain = 'eth'; break;
          case 4: theChain = 'eth'; break;
          case 5: theChain = 'eth'; break;
          case 42: theChain = 'eth'; break;
          case 56: theChain = 'bnb'; break;
          case 137: theChain = 'matic'; break;
          case 25: theChain = 'cro'; break;
          case 100: theChain = 'xdai'; break;
          case 43114: theChain = 'avax'; break;
          case 1666600000: theChain = 'harmony'; break;
          
          default: theChain = 'eth';

        }
        
        async function changeAccount(){
          console.log("Account changed");
          setCurrentChain(theChain);
          setAccount(account);
          console.log("currentChain: " + currentChain);
          console.log("theChain: " + theChain);
        }
    
        if(existingAccount !== account || currentChain !== theChain){
          changeAccount();
          getNFTs(theChain,account);
        }
      } else {
        
        if(currentChain === 'eth'){
          console.log("Not active");
          setNFTList(null);
          setAccount(null);
        }
        
      }
    
    
  },[existingAccount, setAccount, account, active, nftList, chainId]);

  useEffect(() => {

    if(publicKey){
      setAccount(null);
      console.log("Solana Public Key: " + publicKey);
      const res = Axios.post('/api/user/web3', { 
        wallet_address: publicKey,
        chain: 'SOL'
      }).then((res) => {
        console.log(res);
        if(res.data){
          !res.data.success && viewContext.notification.show(res.data.message, 'error', true);
          if(res.data.success || forceWallet){
            setCurrentChain('sol');
            setAccount(forceWallet? forceWallet : publicKey);
            getNFTs('sol', forceWallet? forceWallet : publicKey);

          }
        }
      });
      
    }
  
},[publicKey]);

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

  async function activateNFTs() {
    setHideUnlock(true);
    setProcessing(true); // Enable processing state

    //console.log(nft);
    //console.log(account);

    //console.log(selectedNFTs);
    for (const nft of selectedNFTs) {
      try {
        console.log(nft);
        
        let contract_address = nft.collectionAddress || nft.collection?.address || nft.address;
        let token_id = nft.token_id;
        let nft_pic = nft.image_url;
        let chain = nft.chain;
        let nftInfo = { 
          contract_address: contract_address,
          token_id: token_id,
          nft_pic: nft_pic,
          chain: chain,
          wallet_address: account,
          nftInfo: nft,
          force: forceWallet
        }
        //console.log(nftInfo);
        const res = await Axios.post('/api/nft/track_' + chain, nftInfo);
        
        //console.log(res.data);
        if(res.data.success){
          const updatedSelectedNFTs = [...selectedNFTs];
          let thisNFT = updatedSelectedNFTs.filter(item => item.index === nft.index);
          thisNFT[0].activated = true;
          setSelectedNFTs(updatedSelectedNFTs);
        }
        await delay(500); // Delay of 500 milliseconds
        
        console.log(`NFT ${nft.id} processed successfully`);
      } catch (error) {
        console.error(`Error processing NFT ${nft.index}:`, error);
      }
    }

    

    setSelectedNFTs([]);
    setProcessing(false); // Disable processing state
    setHideUnlock(false);

    viewContext.notification.show("Collectibles Activated! Reloading in 5 seconds", 'success', true);
    setTimeout(() => { getNFTs(currentChain, existingAccount);   }, 5000);
   
    

    /*
    let contract_address = nft.collectionAddress || nft.collection.address;
    let token_id = nft.token_id;
    let nft_pic = nft.image_url;
    let chain = nft.chain;
    const res = await Axios.post('/api/nft/track_' + chain, { 
      contract_address: contract_address,
      token_id: token_id,
      nft_pic: nft_pic,
      chain: chain,
      wallet_address: account,
      nftInfo: nft,
      force: forceWallet
    });
    
    //console.log(res.data);
    setHideUnlock(false);
    if(res.data.success){
      viewContext.notification.show(res.data.message, 'success', true);
      getNFTs(currentChain, existingAccount);
    }
    if(!res.data.success){
      viewContext.notification.show(res.data.message, 'error', true);
      getNFTs(currentChain, existingAccount);
    }
    */
  };

  async function activateNFT(nft) {
    setHideUnlock(true);
    //console.log(nft);
    //console.log(account);
    let contract_address = nft.collectionAddress || nft.collection.address;
    let token_id = nft.token_id;
    let nft_pic = nft.image_url;
    let chain = nft.chain;
    const res = await Axios.post('/api/nft/track_' + chain, { 
      contract_address: contract_address,
      token_id: token_id,
      nft_pic: nft_pic,
      chain: chain,
      wallet_address: account,
      nftInfo: nft,
      force: forceWallet
    });
    //console.log(res.data);
    setHideUnlock(false);
    if(res.data.success){
      viewContext.notification.show(res.data.message, 'success', true);
      getNFTs(currentChain, existingAccount);
    }
    if(!res.data.success){
      viewContext.notification.show(res.data.message, 'error', true);
      getNFTs(currentChain, existingAccount);
    }
  };

  async function changeNFTPage(chg){

    let curPage = pageIndex;
    
    curPage = curPage + chg;
    if(curPage < 0){
      curPage = 0;
    }
    if (curPage > maxPageIndex){
      curPage = maxPageIndex;
    }
    setPageIndex(curPage);


  }

  async function viewNFT2(nft) {
    console.log(nft);
    let traits = nft.traits;
    if(!traits){
      traits = [{trait_type: "Traits", value: "None"}]
    }
    const stringOutput = traits.map(trait => `${trait.trait_type}: ${trait.value}`).join('<br/>');
    viewContext.modal.show(
      {
        title: "Collectible Info",
        buttonText: "OK",
        destructive: false,
        //preText: "Changes: " + res.tokenChanges,
        text: stringOutput,
        //text2: "Don't forget to update your profile pictures on Twitter, etc!",
        //image: res.image,
      },
      () => {}
    ); //modal
  }

  async function viewNFT(nft) {
    //console.log(nft);
    //let navPath = `/nft/holder?id=${nft.nft_id}`;
    let navPath = `/nft/holder/${nft.nft_id}`;
    //console.log(navPath);
    //return <Navigate to={navPath}/>;
    navigate(navPath);
    //console.log(account);
    /*
    let contract_address = nft.asset_contract.address;
    let token_id = nft.token_id;
    let nft_pic = nft.image_url;

    const res = await Axios.post('/api/nft/track', { 
      contract_address: contract_address,
      token_id: token_id,
      nft_pic: nft_pic,
      wallet_address: account
    });
    console.log(res.data);
    */
  };

  if(!authContext.user){
    return (
    <Card dark700 center restrictWidthWide>
      <SignIn title="Signin Required" text="You must signin to use this feature" />
    </Card>
    );
  }

  return (
    <>
    {user && user.data && 
      <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
    }
    <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      

      { (nftList && nftList.length ===0) && 
        <Message
          title='No Collectibles Found.'
          text='We were unable to detect any compatible Collectibles in your connected wallet.'
          type='info'
          key='nftnotfoundmessage'
        />
      }

      <Card dark name='wallets' center key='wallets_connect'>
      
        {!publicKey && 
          <div key='ethconnect' className={'flodat-left'}>
            <span>{chainName()}</span><br/>
            { isMobile &&  <strong className={'text-sm'}>Please use your Mobile Wallet's browser.</strong>}<br/>
            <WalletConnect button={true}/><br/>
            
          </div>
        }
        
        {!active && 
          <Grid cols='2'>
          <div key='solconnect' className={'flodat-left'}>
            <span>Solana Wallet</span><br/>
            { isMobile &&  <strong className={'text-sm'}>Please use your Mobile Wallet's browser.</strong>}<br/>
            <WalletMultiButton className='!bg-gray-900 hover:scale-105' /><br/>
          </div>
          <div key='kdaconnect' className={'flodat-left'}>
            <span>Kadena</span><br/>
            <Button 
              className={ Style.nftLeftButton }
              color="blue"
              variant="contained"
              action={ e => goKadena() }
              text="Connect Kadena"
            />
            </div>
          </Grid>

        }


    
      </Card>
      { !publicKey && !active && !forceWallet && !(currentChain === 'kadena') && (
        <Card dark name='wallets' center key='wallets_connect_message'>
        <span>Connect your wallet to Activate your Collectibles.</span>
        </Card>
      )}
      { (currentChain === 'kadena' || (publicKey || active || forceWallet)) && !nftList && (
        
        <Card dark name='wallets' center key='wallets_loading'>
          <span>Loading Collectibles from wallet. Please wait...</span>
        </Card>
      )}
      { (currentChain === 'kadena' || (publicKey || active || forceWallet)) && nftList && (
        <Card dark name='wallets' center key='wallets_view'>
        <span>These are the Collectibles we could see in your Wallet. Activate a collectible to start tracking it for XP and Rewards. <br/>Select all the Collectibles you'd like to use in Scribe, and click Activate.</span>
        
        {selectedNFTs.length > 0 && 
        <Button 
          className={ Style.nftButton }
          processing={processing}
          color={"green"}
          
          action={ e => activateNFTs() }
          text={"Activate Selected" }
        />
        }
        </Card>
      )}
        
        <Search throttle={ 1000 } callback={ x => setSearch(x) }/><br/>
      
      { (currentChain === 'kadena' || (publicKey || active || forceWallet)) && nftList && nftList.length > 0 && 
        <>
        
        {/*<Message 
          closable
          messageID="Message_YourNFTs2"
          type='info'
          title='Your Collectibles'
          text="These are the Collectibles we could see in your Wallet. Click on one to Manage or Activate it. If you don't see Collectible you own, it could be on a chain we haven't built support for yet!"
      />*/}
        {/*<Card dark name='nftStats' center key='nftStats'>
          <Grid cols='2'>
            <Stat
              loading={ stats?.loading }
              value={ nftList !== null ? nftList.length: "--" }
              label='Collectibles'
              icon='image'
              key="stat1"
            />
            <Stat
              loading={ stats?.loading }
              value={ nftCollections !== null ? nftCollections.length: "--"  }
              label='Communities'
              icon='package'
              key="stat2"
            />
          </Grid>
      </Card>*/}
        
        
      

      {nftList.filter(x => x.unlocked === 0 ).length > 0 && 
        <>
        {isMobile && 
            <div className={'float-clear'}>
              {pageIndex > 0 && 
                <Button key="prevPage" small className={'float-left py-1'} color={"blue"} text={`Previous Page: ${pageIndex + 1}`} action={ e => changeNFTPage(-1)}/>
              }
              {pageIndex < maxPageIndex && 
                <Button key="nextPage" small className={'float-right py-1'} color={"blue"} text={`Next Page: ${pageIndex + 2}`} action={ e => changeNFTPage(1)}/>
              }
              <br/>
              <br/>
            </div>
          }
        <Card dark name='nfts' center id='locked' key="locked"  >
        
          <Grid cols={advanced ? '6' : '4' }>
            { nftList.filter(x => x.unlocked === 0 ).map((nft, index) => {
              //console.log(nft);
              let pageMin = pageIndex * pageSize;
              let pageMax = pageMin + pageSize;
              if(isMobile){
                if(index < pageMin || index >= pageMax){
                  return null;
                }  
              }
              //let unAssignedXP = (nft.xp_raw - nft.xp).toLocaleString();
              

                return (
                  nft.image_url !== null && (nft.image_url.indexOf(".mp4") === -1) && 
                  <Card shadow rounded
                    badge={ nft.status_name } badgeText={ nft.token_name }
                    id={ nft.id }
                    key={ 'nft_id_' + index }
                    className={ nft.selected ? Style.nftLockedSelected : Style.nftLocked }
                    center
                    interactive
                    action={(e) => {
                      viewNFT2(nft);
                    }}
                    >
        
                    <div id="divCollectionName" className={ Style.collection }>
                      { nft.collection?.name ? truncateString(nft.collection.name,25) : nft.collectionID }
                    </div>

                    <div id="divName" className={ Style.nftname }>
                      { nft.nickname? nft.nickname : truncateString(nft.name, 25) }
                    </div>
                    <div id="divTokenID" className={ Style.nftname }>
                      #{ truncateString(nft.token_id, 25) }
                    </div>

                    {/*<div id="divTokenID" className={ Style.nftname }>
                      { truncateString(nft.token_id,25) }
                    </div>*/}
                    
                    {/*<div id="divtitle" className={ Style.nftname }>
                      { nft.title !== undefined ? 
                        nft.title !== "" ? "'" + nft.title + "'" : <>&nbsp;</>
                        : <>&nbsp;</>
                      }
                    </div>*/}

                    <LazyLoadImage 
                      loading="lazy" 
                      className={ Style.nftImage } 
                      src={ nft.image_url } 
                      alt={ nft.token_name} 
                      
                    />

                    {/*<Image
                      className={ Style.nftImage }
                      nftImage = {true}
                      border = {true}
                      source={ nft.image_url}
                      alt={ nft.token_name }
                      
                    />*/}

                    {!hideUnlock && !nft.activated && 
                      <Button 
                        className={ Style.nftButton }
                        tiny
                        color={nft.unlocked === 1 ? "green" : "blue" }
                        variant="contained"
                        action={ e => nft.unlocked === 1 ? viewNFT(nft) : selectNFT(nft) }
                        text={ nft.selected ? "Unselect" : "Select" }
                      />
                    }

                    {nft.activated && 
                      <strong>Activated!</strong>
                    }

                      {/*
                      {(game.status === 1 || game.status === 3) && 
                        <Button title='Play this Game Now!' text='Play' action={ e => History.push('/game/' + game.short_name)}/>
                      }
                      {game.status === 5 && 
                        <Button color='red' title='Coming Soon' text='Coming Soon' action={ e => {}}/>
                      }
                    */}
                      
        
                  </Card>
                )

              })
            }
          </Grid>
        </Card>
        {isMobile && 
          <div className={'float-clear'}>
            {pageIndex > 0 && 
              <Button key="prevPage" small className={'float-left py-1'} color={"blue"} text={`Previous Page: ${pageIndex + 1}`} action={ e => changeNFTPage(-1)}/>
            }
            {pageIndex < maxPageIndex && 
              <Button key="nextPage" small className={'float-right py-1'} color={"blue"} text={`Next Page: ${pageIndex + 2}`} action={ e => changeNFTPage(1)}/>
            }
            <br/>
            <br/>
          </div>
        }
        </>
      } {/*nftlist.filter*/}
      
      </>
      }
      <Feedback />
      </div>
    </>
  )
  
}
